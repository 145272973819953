/*React imports*/
import React, {
  useState
} from 'react';

import ReactDOM from 'react-dom'

/* Ionic component imports */
import {
  IonContent,
  IonPage,
  IonTitle,
  IonSlides,
  IonSlide,
  IonText,
  IonLoading,
  IonGrid,
  IonButton,
  IonCardContent,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonTabButton,
  IonImg,
  IonToast,
  IonCard,
  IonIcon
} from '@ionic/react';

import HeaderSystem from '../components/HeaderSystem'

/*Ionic React lifecycle imports*/
import {
  useIonViewDidEnter,
  useIonViewWillEnter
} from '@ionic/react'

/*Third party plugin imports*/

/*Helper imports*/
import {
  getDoctorSpecialties,
  getDoctorsFromSpecialty,
  getDoctorImageLink,
  DoctorSpecialtyData,
  getSpecialtyFromID
} from '../helpers/doctors'


import {
  getUserUIDWithAuth
} from '../helpers/users'

/*Image, Data, and Icon imports*/

import {
  starOutline,
  help
} from 'ionicons/icons'

const Doctors: React.FC = () => {
  /* Contexts */

  /* States and setters */
  const [busy, setBusy] = useState(true)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastHeader, setToastHeader] = useState('')


  const [currentSpecialty, setCurrentSpecialty] = useState<DoctorSpecialtyData>()


  /* Functions */
  // Grab all the doctor objects and render them to the view
  async function loadDoctors(selectedSpecialty: DoctorSpecialtyData) {
    setBusy(true)

    let doctorList = await getDoctorsFromSpecialty(selectedSpecialty).then((doctorList) => {
      return doctorList
    })

    setCurrentSpecialty(selectedSpecialty)

    let slides: JSX.Element[] = []

    let i
    for (i = 0; i < doctorList.length; i++) {
      let doctor = doctorList[i]
      let imageURL: string = await getDoctorImageLink(selectedSpecialty, doctor)
      // let whatsappRef = "https://wa.me/" + doctor.phoneNumber
      // let callRef = "tel:" + doctor.phoneNumber
      // let callRef = "tel:+912248972607"
      // let emailRef = "mailTo:" + doctor.email

      slides.push(
        <IonSlide key={doctor.id}>
          <IonCard>
            <IonCardContent>
              <IonGrid>
                <div className="doctor-info-container">
                  <IonRow>
                    <IonCol>
                      <IonText class="cardTitle">
                        {doctor.fullName}
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="7">
                      <IonImg
                        src={imageURL}
                        alt={"Photo of " + doctor.fullName}
                      />
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonText>
                        {doctor.city}, {doctor.state}, {doctor.country}
                      </IonText>
                    </IonCol>
                  </IonRow>
                </div>

              </IonGrid>
            </IonCardContent>

          </IonCard>
        </IonSlide>
      )
    }

    let swiperContainer = document.getElementById("slideContainerDoctors")
    if (swiperContainer !== null) {
      ReactDOM.render(slides, swiperContainer.getElementsByClassName("swiper-wrapper").item(0))
    }

    setBusy(false)
  }

  async function loadDoctorSpecialties() {
    let specialtyList = await getDoctorSpecialties().then((specialtyList) => {
      return specialtyList
    })

    let selectOptions: JSX.Element[] = []

    let i
    for (i = 0; i < specialtyList.length; i++) {

      let specialty = specialtyList[i]
      selectOptions.push(
        <IonSelectOption value={specialty} key={specialty.id}>
          {specialty.title}
        </IonSelectOption>
      )
    }

    ReactDOM.render(selectOptions, document.getElementById("specialtySelector"))

  }

  async function setFirstDoctors() {
    let firstSpecialty = await getSpecialtyFromID('general')
    if (firstSpecialty) {
      await loadDoctors(firstSpecialty)
    }
  }

  /* Lifecycle methods */
  useIonViewWillEnter(() => {
    setBusy(true)
  })


  useIonViewDidEnter(() => {
    window.history.pushState({}, '', window.top.location.pathname + window.top.location.search)
    loadDoctorSpecialties().then(() => {
      setFirstDoctors()
    })
  })

  return (
    <IonPage>
      <HeaderSystem pageTitle="Doctors" />
      <IonLoading
        message="Load ho raha hai..."
        duration={0}
        isOpen={busy}
      />
      <IonContent fullscreen className="ion-padding">
        <IonGrid class="contentGrid">
          <IonRow>

            <IonCol>
              <IonTitle size="small">Doctor Appointments</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                href="https://calendly.com/beneficiary-1/doctor-appointment"
              >
                Book Kariye
    </IonButton>
    &nbsp;
              <IonIcon
                src={help}
                class="circleIconButton"
                onClick={() => {
                  setToastHeader("Yahan aap Doctors se sampark kariye!")
                  setToastMessage('(1) Phone call par ya (2) Message se ya (3) Myna center mein');
                  setShowToast(true)
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                href={"https://docs.google.com/forms/d/e/1FAIpQLSe9sP6_CDv8nb2suwx2RkuDDDeT8Vrqi14LCfxWwO_SRMLJBA/viewform?usp=pp_url&entry.184496598=" + getUserUIDWithAuth()}
              >
                Case Form
    </IonButton>
    &nbsp;
              <IonIcon
                src={help}
                class="circleIconButton"
                onClick={() => {
                  setToastHeader('Myna Clinic ke liye case form bhariye')
                  setToastMessage('Ye doctor se baat karne ke liye hai');
                  setShowToast(true)
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>Appointment kaisa laga?</IonText>
              &nbsp;
              <IonIcon
                src={help}
                class="circleIconButton"
                onClick={() => {
                  setToastHeader('')
                  setToastMessage('Aapka feedback aapke agle apppointment ko behter karne ke liye hai!')
                  setShowToast(true)
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow style={{ border: "solid 2px var(--ion-color-primary)", bordeRadius: "3px", backgroundColor: "white" }}>
            <IonCol>
              <IonTabButton
                class="functionalTabButton"
                href={"https://docs.google.com/forms/d/e/1FAIpQLSfSX7_fupedDyho8qm3vMoYn48krJ8LsMSZT5tw0zi8yAco6Q/viewform?usp=pp_url&entry.205476722=1&entry.2081973432=" + getUserUIDWithAuth()}>
                <IonIcon
                  icon={starOutline}
                />
              </IonTabButton>
            </IonCol>
            <IonCol>
              <IonTabButton
                class="functionalTabButton"
                href={"https://docs.google.com/forms/d/e/1FAIpQLSfSX7_fupedDyho8qm3vMoYn48krJ8LsMSZT5tw0zi8yAco6Q/viewform?usp=pp_url&entry.205476722=2&entry.2081973432=" + getUserUIDWithAuth()}>
                <IonIcon
                  icon={starOutline}
                />
              </IonTabButton>
            </IonCol>
            <IonCol>
              <IonTabButton
                class="functionalTabButton"
                href={"https://docs.google.com/forms/d/e/1FAIpQLSfSX7_fupedDyho8qm3vMoYn48krJ8LsMSZT5tw0zi8yAco6Q/viewform?usp=pp_url&entry.205476722=3&entry.2081973432=" + getUserUIDWithAuth()}>
                <IonIcon

                  icon={starOutline}
                />
              </IonTabButton>
            </IonCol>
            <IonCol>
              <IonTabButton
                class="functionalTabButton"
                href={"https://docs.google.com/forms/d/e/1FAIpQLSfSX7_fupedDyho8qm3vMoYn48krJ8LsMSZT5tw0zi8yAco6Q/viewform?usp=pp_url&entry.205476722=4&entry.2081973432=" + getUserUIDWithAuth()}>
                <IonIcon

                  icon={starOutline}
                />
              </IonTabButton>
            </IonCol>
            <IonCol>
              <IonTabButton
                class="functionalTabButton"
                href={"https://docs.google.com/forms/d/e/1FAIpQLSfSX7_fupedDyho8qm3vMoYn48krJ8LsMSZT5tw0zi8yAco6Q/viewform?usp=pp_url&entry.205476722=5&entry.2081973432=" + getUserUIDWithAuth()}>
                <IonIcon

                  icon={starOutline}
                />
              </IonTabButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonSelect
                placeholder="Doctor Type Select Kariye"
                id="specialtySelector"
                interface="popover"
                interfaceOptions={{ header: "Doctor Type" }}
                onIonChange={(e) => {
                  setBusy(true);
                  loadDoctors(e.detail.value).then(
                    () => { setBusy(false) })
                }} >
              </IonSelect>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonTitle size="small">{currentSpecialty ? currentSpecialty.title : ''}</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>
                {currentSpecialty ? currentSpecialty.description : ''}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonSlides
                id="slideContainerDoctors"
                options={{ slidesPerView: 1.1 }}
                pager={true}
              >
              </IonSlides>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          header={toastHeader}
          buttons={['Close']}
          duration={10000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Doctors;
