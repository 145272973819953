// import * as textToSpeech from '@google-cloud/text-to-speech'
// import * as fs from 'fs'
// import * as util from 'util'
import firebase from 'firebase/app'

import { firestore } from './firebaseConfig'
import { UserData } from './users'

export class TipData {
  tipContent: string
  tipIndex: string

  constructor(tipDataObject: firebase.firestore.DocumentData) {
    this.tipContent = tipDataObject.data().tip
    this.tipIndex = tipDataObject.id
  }
}

// const ttsClient = new textToSpeech.TextToSpeechClient();

// Take in a list of tips and export the data to Firestore if the user is an
// admin. This data is stored in 'src/assets/data/tipsData', but the calling
// page does the import to avoid loading a long list in the buffer for non-admin
// pages.
export async function exportTipsDataToFirestore(
  tipsData: Array<{ tipContent: string, tipIndex: string }>,
  tipsCategoryID: string) {
  try {
    if (tipsData.length === 0) {
      throw new Error('Tips data was empty')
    }

    let categoryDocRef = firestore.collection('tips').doc(tipsCategoryID);

    console.log("Checking for existing category document...")
    if ((await categoryDocRef.get()).exists) {
      throw new Error(tipsCategoryID + ' document must be deleted from the Firebase console first')
    }

    console.log("Generating new category document...")
    await categoryDocRef.set({
      numTips: tipsData.length
    })

    console.log("Generating new tip subdocuments...")
    for (let i = 0; i < tipsData.length; i++) {
      console.log(i)
      let tip = tipsData[i]
      await categoryDocRef.collection('tips').doc(tip.tipIndex).set({
        tip: tip.tipContent
      })
    }



    return true
  }
  catch (error) {

    if (error.code) {
      return error.code
    }
    else {
      return error
    }
  }

}


// // Creats an audio file for a given tip
// async function createTipAudio(tipText: string, tipIndex: number) {
//   // The text to synthesize
//   const text = 'hello, world!';
//
//
//
//   // Construct the request
//   const request = new textToSpeech.protos.google.cloud.texttospeech.v1.SynthesizeSpeechRequest(
//     {
//       input: { text: text },
//       // Select the language and SSML voice gender (optional)
//       voice: { languageCode: 'en-US', ssmlGender: 'NEUTRAL' },
//       // select the type of audio encoding
//       audioConfig: { audioEncoding: 'MP3' },
//     }
//   )
//
//   // Performs the text-to-speech request
//   const [response] = await ttsClient.synthesizeSpeech(request);
//   // Write the binary audio content to a local file
//   const writeFile = util.promisify(fs.writeFile);
//   await writeFile('output.mp3', response.audioContent, 'binary');
//   console.log('Audio content written to file: output.mp3');
// }

// Get the content and readable number of a random tip for a given category
export async function getRandomTip(tipsCategoryID: string) {
  try {
    let categoryDocRef = firestore.collection('tips').doc(tipsCategoryID);
    let categoryData = (await categoryDocRef.get()).data()
    if (!categoryData) throw new Error("No data in tips category document")

    let numTips = categoryData.numTips
    if (!numTips) throw new Error("No numTips found on data in tips category document")

    let chosenTipIndex = Math.round(Math.random() * numTips)

    let tipDocRef = categoryDocRef.collection('tips').doc(chosenTipIndex.toString())
    let tipDataObject = new TipData(await tipDocRef.get())


    return tipDataObject
  }
  catch (error) {
    console.log(error)

    return null
  }
}

// Get the content and readable number of the next tip in a given category
export async function getNextTip(tipsCategoryID: string, currentTip: TipData) {

  try {
    let newTipIndex = parseInt(currentTip.tipIndex) + 1

    let categoryDocRef = firestore.collection('tips').doc(tipsCategoryID);
    let categoryData = (await categoryDocRef.get()).data()
    if (!categoryData) throw new Error("No data in tips category document")

    let numTips = categoryData.numTips
    if (!numTips) throw new Error("No numTips found on data in tips category document")

    if (newTipIndex >= numTips) {
      newTipIndex = 0
    }

    let tipDocRef = categoryDocRef.collection('tips').doc(newTipIndex.toString())
    let tipDataObject = new TipData(await tipDocRef.get())

    return tipDataObject
  }
  catch (error) {
    console.log(error)

    return null
  }
}

// Get the content and readable number of a specific tip in a given category
export async function getTipByIndex(tipsCategoryID: string, tipIndex: number) {

  try {

    let categoryDocRef = firestore.collection('tips').doc(tipsCategoryID);
    let categoryData = (await categoryDocRef.get()).data()
    if (!categoryData) throw new Error("No data in tips category document")

    let numTips = categoryData.numTips
    if (!numTips) throw new Error("No numTips found on data in tips category document")

    if (tipIndex >= numTips) {
      tipIndex = 0
    }

    let tipDocRef = categoryDocRef.collection('tips').doc(tipIndex.toString())
    let tipDataObject = new TipData(await tipDocRef.get())

    return tipDataObject
  }
  catch (error) {
    console.log(error)

    return null
  }
}

// Get the content and readable number of the previous tip for a given category
export async function getPreviousTip(tipsCategoryID: string, currentTip: TipData) {

  try {
    let newTipIndex = parseInt(currentTip.tipIndex) - 1

    let categoryDocRef = firestore.collection('tips').doc(tipsCategoryID);
    let categoryData = (await categoryDocRef.get()).data()
    if (!categoryData) throw new Error("No data in tips category document")

    let numTips = categoryData.numTips
    if (!numTips) throw new Error("No numTips found on data in tips category document")

    if (newTipIndex < 0) {
      newTipIndex = numTips - 1
    }

    let tipDocRef = categoryDocRef.collection('tips').doc(newTipIndex.toString())
    let tipDataObject = new TipData(await tipDocRef.get())

    return tipDataObject
  }
  catch (error) {
    console.log(error)

    return null
  }
}

// Gets the user's feedback for a given video. Creates a new entry if
// one does not exist
export async function getOrCreateCurrentUserTip(tipsCategoryID: string, user: UserData) {
  let categoryDocRef = firestore.collection('tips').doc(tipsCategoryID);

  if (!user.currentTipIndex) {
    // Create a new currentTipIndex field

    let userRef = firestore.collection('users').doc(user.uid)

    await userRef.update({
      currentTipIndex: 0
    })


    let tipDocRef = categoryDocRef.collection('tips').doc('0')
    let tipDataObject = new TipData(await tipDocRef.get())
    return tipDataObject
  }
  else {
    // return user.currentTipIndex
    let tipDocRef = categoryDocRef.collection('tips').doc(user.currentTipIndex.toString())
    let tipDataObject = new TipData(await tipDocRef.get())
    return tipDataObject

  }

}

/* Update the user entry in Firestore with a new tip */
export async function setUserCurrentTipIndex(tip: TipData, user: UserData) {
  let userRef = firestore.collection('users').doc(user.uid)

  user.currentTipIndex = parseInt(tip.tipIndex)

  await userRef.update({
    currentTipIndex: user.currentTipIndex
  })
}
