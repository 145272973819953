export const generalTipsData = [
  {
    "tipIndex": "0",
    "tipContent": "Aap apne din ki shuruat 3 glass paani se kar sakte hai. Roz 8 glass (2 litres) paani peena avashyak hai."
  },
  {
    "tipIndex": "1",
    "tipContent": "Paryapt aaram lene swasthya ke liye achha hota hai. Hame kam se kam 7-9 ghante sona chahiye."
  },
  {
    "tipIndex": "2",
    "tipContent": "3 bade bhojano ki jagah ek din mein 6 chhote bhojan khane ki koshish kare aur har mein poshak tatvo (jaise lean protein, carbohydrates, fal, sabziya aur swastha vasa jaise olive oil aur avacado) ko shamil karne ka dhyan rakhe. Yeh aapke blood sugar ko star banaye rakhne mein madad karega jisse aapko urja milegi."
  },
  {
    "tipIndex": "3",
    "tipContent": "Kasrat aapke hormonal asantulan ko niyantrit karne mein madad karega. Aapko har din kam se kam 30-60 min kasrat karni chahiye."
  },
  {
    "tipIndex": "4",
    "tipContent": "Asamanya discharge dikhne mein hara, peela ya bhura ho sakta hai apriya gandh ke sath. Isse aapko khujli bhi mehsoos ho sakti hai."
  },
  {
    "tipIndex": "5",
    "tipContent": "Bloating ya pet ke foolne se bachne ke liye cold drink aur caffeine kam peeye.Bloating se bachne ke liye paryapt matra mein paani peeye. Yeh pachan mein bhi aapki madad karega."
  },
  {
    "tipIndex": "6",
    "tipContent": "Kam caffeine, cold drink (soda, coffe drinks, chocolates) peene se aapko period ke dino mein bloating kam hogi, tanav, chidchidapan aur stan dard bhi kam mehsoos hoga."
  },
  {
    "tipIndex": "7",
    "tipContent": "Ek niyamit karyakram banaye rakhne ki koshish kare. Isme bhoja, kasrat aur sone ka samay bhi shamil hai."
  },
  {
    "tipIndex": "8",
    "tipContent": "Koi bhojan na chhode. Nashta aapke din ko banaye rakhne ke liye bohot mehttvapurna hai."
  },
  {
    "tipIndex": "9",
    "tipContent": "Niyamit jaach karaye. Aapka doctor aapki medical history pr nazar rakhta hai aur aapko swasth rehne mein sahita kar sakta hai. Isse aapka doctor kisi lakshan ko pehchan unka ilaaj kar sakta hai."
  },
  {
    "tipIndex": "10",
    "tipContent": "Sab kuchh ek sath badalne ki koshish na kare! Ek he sath bade badlav karne ki jagah har hafte kuchh chhote badlav kare. Naya bhojan aazmaye, ya khane ki matra ko badle aur apne shareer ko in parivartano se adjust karne de."
  },
  {
    "tipIndex": "11",
    "tipContent": "Soda ko neembu paani/ ganne ka ras/ juice se badle."
  },
  {
    "tipIndex": "12",
    "tipContent": "Apne shareer ki sune."
  },
  {
    "tipIndex": "13",
    "tipContent": "Aswasthyakari aahar khane se, kasrat na karne se, adhik vajan hone se, adhik sharab peene se hriday rog yani heart disease hone ki sambhavna badh jati hai."
  },
  {
    "tipIndex": "14",
    "tipContent": "Anuvanshik breast ya ovarian cancer syndrome (HBOC) ki jaach karaye. Pata lagaye yadi aapke parivar mein inn bimariyo ka itihas raha chuka hai."
  },
  {
    "tipIndex": "15",
    "tipContent": "Fal aur sabziya khana sehat ke liye achha hota hai aur Vitamin C ghav ko thik karne mein sahita karta hai."
  },
  {
    "tipIndex": "16",
    "tipContent": "Shareer mein pet ke waha motapan mahilao mein menopause ke baad hone lagta hai jaise estrogen level kam hote hai- hormones shareer mein vasa vitran mein bhumika nibhate hai. Cardio, weight lifting aur core work karne se pet ke shetra mein fat buildup rokne mein madad mil sakti hai."
  },
  {
    "tipIndex": "17",
    "tipContent": "Menopause baad mahilao ko unke cholestrol pr khas dhyan dena chahiye."
  },
  {
    "tipIndex": "18",
    "tipContent": "Pero mein sujan aksar fluid retention ya edema ke karan hoti hai- jab paani sanchar pranali yani circulatory system mein rehta hai. Iska sabse pradhan karan sodium ka zyada sevan hai. Kidney hamare drav santulan ke liye zimmedar hai, toh jab vo dhang se kaam na kare tab fluid build up ho sakta hai."
  },
  {
    "tipIndex": "19",
    "tipContent": "Gehri saans le. Oxygen jeevan ke liye mehttvapurna hai. Ek puri saans voh hoti hai jab aapke fefde puri tarah bhare hue ho, pet fela ho aur kandhe bilkul kam hile."
  },
  {
    "tipIndex": "20",
    "tipContent": "Bhojan ke doran paani peene se bache kyuki yah paachan prakriya ko prabhavit karta hai."
  },
  {
    "tipIndex": "21",
    "tipContent": "5 minute ke liye dheere dheere chale aur fir dheere dheere apni gati badhaye (Din mein kam se kam 20 minute pedal chale). Yeh vazan niyantran, blood circulation yani rakt parischaran ko protsahit karne aur haddiyo ko mazboot banane mein madad karta hai."
  },
  {
    "tipIndex": "22",
    "tipContent": "Acidity ki sthiti mein dahi aur ghera kmal ka kaam kar sakta hai."
  },
  {
    "tipIndex": "23",
    "tipContent": "Amrood, Tarbooz, Papite, Strawberry aur plum jaise fibrous fal aapke blood sugar ko nahi badhate."
  },
  {
    "tipIndex": "24",
    "tipContent": "Anemia se bachne ke liye chukundar aur gajar ka juice peeye. Gud aur drumstick bhi lohe yani iron se bharpoor hote hai."
  },
  {
    "tipIndex": "25",
    "tipContent": "Acne aur pimple se bachne ke liye nuts aur vitamin c se bharpur fan aur sabziya khaye aur chocolates, mithaiya aur namkeen snacks aur cheeni kam khaye."
  },
  {
    "tipIndex": "26",
    "tipContent": "Bhook kam lagne pr taze fal aur sabziya khaye. Namak, chai aur coffee se bache."
  },
  {
    "tipIndex": "27",
    "tipContent": "Apachan- chhote, lagatar bhojan khaye aur fizzi drinks, sharab aur coffee kam peeye."
  },
  {
    "tipIndex": "28",
    "tipContent": "Swasth kidneys ke liye self-help steps- Khoob paani peeye. Apni bathroom ko na roke aur turant jaye. Processed aur dibbaband khadya padarth kam khaye."
  },
  {
    "tipIndex": "29",
    "tipContent": "Mal tyag ke baad dhyan rakhiye  ki aap aage se peechhe saaf kare aur peechhe se aage nahi."
  },
  {
    "tipIndex": "30",
    "tipContent": "Yoni ko saaf karne ke liye sabun ka upyog na kare. Yeh ek sva-safai wala ang hai, saade pani ka upyog kare."
  },
  {
    "tipIndex": "31",
    "tipContent": "Sprouts, Beej, aur hari sabziya bina pakaye khaye kyuki unme adhik poshan hota hai."
  },
  {
    "tipIndex": "32",
    "tipContent": "Mosami falo ko bhojan se pehle khaye taki adhiktam avshoshan ho sake."
  },
  {
    "tipIndex": "33",
    "tipContent": "Falo se wax yani mom hatane ke liye unhe karm pani se dhoye."
  },
  {
    "tipIndex": "34",
    "tipContent": "Apna kaam din ke karya shuru karne se pehle nashta kare Apne nashte ke 4 ghante baad din ka khana aur sone ke 2 ghante pehle raat ka khana khaye."
  },
  {
    "tipIndex": "35",
    "tipContent": "Upvas ke doran dhyan rakhe ki aap achhi tarah paani peeye, aaram kare aur din mein do baar nahaye."
  },
  {
    "tipIndex": "36",
    "tipContent": "Bhojan ke baad thanda paani na peeye kyuki yeh pachan prakriya ko dheema karta hai."
  },
  {
    "tipIndex": "37",
    "tipContent": "Nashte mein biscuit na khaye. Yeh pochak tattvo mein kam aur cheeni mein zyada hote hai."
  },
  {
    "tipIndex": "38",
    "tipContent": "Jajar, Shakarkand aur hari sabziya aakh sabandhit rogo ko thik kar sakti hai."
  },
  {
    "tipIndex": "39",
    "tipContent": "Turant shakti ke liye kele, khajur aur kishmish khaye."
  },
  {
    "tipIndex": "40",
    "tipContent": "Juice peene ki tulna mein fal khana zyada faidemand hai"
  },
  {
    "tipIndex": "41",
    "tipContent": "Apni pratiraksha pranali ka nirman karne ke liye protein yukt bhojan, khatte fal, palak, shakarkand aur gajar khaye. Cheeni aur caffein kam kare."
  },
  {
    "tipIndex": "42",
    "tipContent": "Migraine? Chocolate, paneer, khatte fal aur caffeine se bache."
  },
  {
    "tipIndex": "43",
    "tipContent": "Dudh, mass, machhli aur ande jaise pashu padarth aur daalo aur faliyo jaise khadya padarth protein se bharpur hote hai."
  },
  {
    "tipIndex": "44",
    "tipContent": "Urja yukt khadya padarth- Carbohydrate, sabut anaj, bajra, tel, ghee, makkhan, nut aur tilhan aur shakkar."
  },
  {
    "tipIndex": "45",
    "tipContent": "Body building food- Protein, daal, nuts, telhan, mass, dudh aur dudh se bane padarth, machhli"
  },
  {
    "tipIndex": "46",
    "tipContent": "Surakshatmak khadya padarth- Vitamin aur khaneej- Hari patti wali sabziya, fal, ande, dudh aur dudh adpad"
  },
  {
    "tipIndex": "47",
    "tipContent": "Vitamin A se bharpur khana- Hari pattidar sabziya, gajar, tamatar, shakarkand, papita, aam adi"
  },
  {
    "tipIndex": "48",
    "tipContent": "Vitamin C se bharpoor khadya padarth khaye jaise taza aamla, khatte fal, amrud, kela aur tamatar jaisi sabziya"
  },
  {
    "tipIndex": "49",
    "tipContent": "Calcium yukt khadya padarth jaise dudh, dahi, ragi aur nuts"
  },
  {
    "tipIndex": "50",
    "tipContent": "Hari pattidar sabziya jaise faliya, nuts aur liver folic acid yukt hote hai jo garbhavstha ke doran bohot mehttvapurn hai."
  },
  {
    "tipIndex": "51",
    "tipContent": "Sabut anaj, chane aur sag ke sayojan ka upyog kare. Calories ya urja mein kami ko niyantrit karne ke liye gud, cheeni aur khana pakane ke tel ka prayog kare."
  },
  {
    "tipIndex": "52",
    "tipContent": "Swasth khane ki aadatein viksit kare aur niyamit roop se vyayam kare aur gatiheen jeevan sheli se bachne ki koshish kare."
  },
  {
    "tipIndex": "53",
    "tipContent": "Ande mein kai mehttvapurn poshak tattva hote hai parantu sath he usme cholestrol ki matra bhi adhik hoti hai. Hafte mein keval 3 ande khane ki koshish kare. Halaki, ande ke safed hisse ka sevan achhi matra mein kiya jaa sakta hai."
  },
  {
    "tipIndex": "54",
    "tipContent": "Ready to eat khadya padarth, Hydrogenated fat mein taiyar fast food, bakery padarth ka sevan kam kare."
  },
  {
    "tipIndex": "55",
    "tipContent": "Atyadhik shareer ke vazan se hridya rog, uchh raktachaap, madhumeh, pitt pathari, kuchh prakar ke cancer aur osteoarthritis ka khatra badh jata hai."
  },
  {
    "tipIndex": "56",
    "tipContent": "Shareer ke vazan mein dheeme aur sthir kami ki salah di jati hai."
  },
  {
    "tipIndex": "57",
    "tipContent": "Gambhir upvas aur bhojan ko chhodne se swastha ko khatra ho sakta hai."
  },
  {
    "tipIndex": "58",
    "tipContent": "Madhyam teevrata ki shararik gatividhi ke liye prati din chalees minute kai svasthya labh pradan karte hai."
  },
  {
    "tipIndex": "59",
    "tipContent": "Sanrakshit aur prasanskrit khadya padartho jaise papad, achar, sauce,  namkeen biscuit, chips, cheese aur namkeen machhli. Zyada namak khane se bache."
  }
]
