/*React imports*/
import React, {
  useState,
  useContext,
  useRef
} from 'react';

/* Ionic component imports */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonInput,
  IonButton,
  IonToast,
  IonLoading,
  NavContext,
  IonGrid,
  IonRow,
  IonCol,
  IonRouterLink
} from '@ionic/react';

/*Ionic React lifecycle imports*/
import {
  useIonViewWillEnter
} from '@ionic/react'

/*Third party plugin imports*/
import firebase from 'firebase/app'
import "firebase/auth"

/*Helper imports*/
import {
  trackUserLoggedInWithPhoneNumber
} from '../helpers/tracking'

import {
  loginSendOTPWithAuth,
  checkIfUserExists,
  logoutAndDeleteUserWithAuth,
  getRecaptchaVerifierWithAuth
} from '../helpers/users'

/*Image, Data, and Icon imports*/
import LoginGirl from "../assets/images/login-girl.png"

const LoginPhone: React.FC = () => {
  /* Contexts */
const navigationContext = useContext(NavContext)

  /* States and setters */
  const [busy, setBusy] = useState<boolean>(true)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setShowToastMessage] = useState("Default error")

  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('+91')
  const [inputOTP, setInputOTP] = useState('')
  const [hideOTPInput, setHideOTPInput] = useState(true)

  const [sendOTPButtonText, setSendOTPButtonText] = useState('OTP Bheje')
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<firebase.auth.RecaptchaVerifier | null>(null)
  const [confirmationResult, setConfirmationResult] = useState<firebase.auth.ConfirmationResult | null>(null)

  const countryCodeInputRef = useRef<HTMLIonInputElement>(null)
  const phoneInputRef = useRef<HTMLIonInputElement>(null)
  const sendOTPButtonRef = useRef<HTMLIonButtonElement>(null)
  const otpInputRef = useRef<HTMLIonInputElement>(null)
  const submitOTPButtonRef = useRef<HTMLIonButtonElement>(null)

/* Functions */
  async function handleEnterPress(e: any, field: React.RefObject<HTMLIonInputElement>) {
    if (e.key === "Enter") {
      if (field === countryCodeInputRef) {
        if (phoneInputRef.current) phoneInputRef.current.setFocus()
      }
      else if (field === phoneInputRef) {
        if (phoneInputRef.current) phoneInputRef.current.setBlur()
        if (sendOTPButtonRef.current) sendOTPButtonRef.current.click()
      }
      else if (field === otpInputRef) {
        if (otpInputRef.current) otpInputRef.current.setBlur()
        if (submitOTPButtonRef.current) submitOTPButtonRef.current.click()
      }
    }
  }

  async function sendOTP() {
    // Force typescript to acknowledge that recaptchaVerifier will never be null below
    if (recaptchaVerifier === null) {
      return
    }


    // setBusy(true)
    if (phoneNumber === '') {
      setShowToastMessage('Phone number darj nahi kiya gaya hai.')
      setShowToast(true)
    }
    else if (countryCode === '') {
      setShowToastMessage('Country code darj nahi hua hai.')
      setShowToast(true)
    }

    else {
      let sendOTPResult = await loginSendOTPWithAuth(recaptchaVerifier, countryCode + phoneNumber)
      // recaptchaVerifier.clear();
      // grecaptcha.reset();
      console.log("Finished loginSendOTPWithAuth function")
      if (sendOTPResult[0] === true) {
        // Logged in succesfully
        console.log("Sent OTP")
        // navigationContext.navigate("/dashboard", "forward")
        // window.history.push('/dashboard')
        setHideOTPInput(false)
        setSendOTPButtonText('OTP fir se bheje')
        setConfirmationResult(sendOTPResult[1])
      }
      else if (sendOTPResult[1] === 'auth/invalid-phone-number') {
        setShowToastMessage('Phone number sahi tarah se darj nahi hua hai.')
        setShowToast(true)
      }
      else if (sendOTPResult[1] === 'auth/code-expired') {
        setShowToastMessage('OTP expire hua hai. OTP ke liye fir se request kariye.')
        setShowToast(true)
      }
      else if (sendOTPResult[1] === 'auth/internal-error') {
        setShowToastMessage('Apne phone ko Wi-Fi ya Data (Internet) se connected rakhein.')
        setShowToast(true)
      }
      else {
        setShowToastMessage('Unknown error with sending OTP: ' + sendOTPResult[1])
        setShowToast(true)
      }
    }
  }


  async function submitOTP() {
    if (confirmationResult === null) {
      return
    }

    setBusy(true)
    confirmationResult.confirm(inputOTP)
      .then((result) => {
        console.log("Signed in successfully")

        if (result.user === null) {
          setShowToastMessage('Got a null user object from auth.')
          setShowToast(true)
        }
        else {
          const userExists = checkIfUserExists(result.user.uid)
          userExists.then((result) => {
            if (result === true) {
              let tabBar = document.getElementById("tabBar")
              if (tabBar) {
                tabBar.style.display = "flex"
              }
              setBusy(false)
              trackUserLoggedInWithPhoneNumber()
              navigationContext.navigate("/dashboard", "forward")
              window.location.reload()
              // @ts-ignore
              window.Intercom('shutdown')
            }
            else if (result === false) {
              // Force the user to enter new account info
              logoutAndDeleteUserWithAuth()
              setShowToastMessage('Yeh phone number nahi mila hai. Kya aapko pehle REGISTER karna hai?')
              setTimeout(() => {
                window.location.reload()
              }, 3000)
              setShowToast(true)
            }
            else {
              setShowToastMessage('Error getting user object from db.')
              setShowToast(true)
            }
          })
        }
      }).catch((error) => {
        if (error.code === 'auth/invalid-verification-code') {
          setShowToastMessage('OTP sahi nahi hai.')
          setShowToast(true)
        }
        else {
          setShowToastMessage('Unknown OTP validation error: ' + error.code)
          setShowToast(true)
        }
      })
    setBusy(false)
  }

/* Lifecycle methods */
  useIonViewWillEnter(() => {
    // @ts-ignore
    window.Intercom('shutdown')

    console.log("Creating recaptcha")
    setRecaptchaVerifier(getRecaptchaVerifierWithAuth("recaptchaContainer"));
    let tabBar = document.getElementById("tabBar")
    if (tabBar) {
      tabBar.style.display = "none"
    }
    setBusy(false)
  })


  return (
    <IonPage>
      <IonHeader>
      </IonHeader>
      <IonLoading
        message="Load ho raha hai..."
        duration={0}
        isOpen={busy}
      />
      <IonContent fullscreen className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonTitle size="large">Phone Login</IonTitle>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="2" />
            <IonCol size="2">
              <IonInput
                enterkeyhint="next"
                inputmode="tel"
                type="tel"
                value={countryCode}
                ref={countryCodeInputRef}
                onKeyPress={(e: any) => handleEnterPress(e, countryCodeInputRef)}
                onIonChange={(e: any) => setCountryCode(e.target.value)}>
              </IonInput>
            </IonCol>
            <IonCol>
              <IonInput
                enterkeyhint="send"
                inputmode="tel"
                type="number"
                placeholder="Phone number"
                ref={phoneInputRef}
                onKeyPress={(e: any) => handleEnterPress(e, phoneInputRef)}
                onIonChange={(e: any) => setPhoneNumber(e.target.value)}>
              </IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                class="verificationButton"
                type="submit"
                ref={sendOTPButtonRef}
                onClick={sendOTP}>
                {sendOTPButtonText}
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput
                enterkeyhint="send"
                hidden={hideOTPInput}
                type="number"
                ref={otpInputRef}
                onKeyPress={(e: any) => handleEnterPress(e, otpInputRef)}
                placeholder="OTP"
                onIonChange={(e: any) => setInputOTP(e.target.value)}>
              </IonInput>
              <div id="recaptchaContainer" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                class="verificationButton"
                type="submit"
                hidden={hideOTPInput}
                ref={submitOTPButtonRef}
                onClick={submitOTP}>
                OTP Submit Kariye
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonRouterLink href="/landing">Vaapas Jaaye</IonRouterLink>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <img src={LoginGirl} alt="Myna login girl" />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          buttons={['Retry']}
          duration={4000}
        />

      </IonContent>
    </IonPage>
  );
};

export default LoginPhone;
