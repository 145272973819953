/*React imports*/
import React, {
  useState,
  useContext
} from 'react';

/* Ionic component imports */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonInput,
  IonButton,
  IonToast,
  IonLoading,
  NavContext,
  IonGrid,
  IonRow,
  IonText,
  IonCol,
  IonAlert,
  IonRouterLink
} from '@ionic/react';

/*Ionic React lifecycle imports*/
import {
  useIonViewWillEnter
} from '@ionic/react'

/*Third party plugin imports*/

import Calendar from 'react-calendar'

import {
  CountryDropdown,
  RegionDropdown
} from 'react-country-region-selector'

import {
  Geolocation
} from '@ionic-native/geolocation'

/*Helper imports*/

import {
  getCurrentUser,
  addCurrentUserToDatabase
} from '../helpers/users'

/*Image, Data, and Icon imports*/


const UpdateProfileInfo: React.FC = () => {
  /* Contexts */
  const navigationContext = useContext(NavContext)

  /* States and setters */
  const [busy, setBusy] = useState<boolean>(true)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setShowToastMessage] = useState("Default error")

  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [birthYear, setBirthYear] = useState<number>()
  const [birthMonth, setBirthMonth] = useState<number>()
  const [birthDay, setBirthDay] = useState<number>()


  const [country, setCountry] = useState('')
  const [region, setRegion] = useState('')
  const [city, setCity] = useState('')

  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)

  const [admin, setAdmin] = useState(false)
  const [mynaID, setMynaID] = useState('')
  const [uid, setUID] = useState('')

  const emailInputRef = React.createRef<HTMLIonInputElement>()
  const countryCodeInputRef = React.createRef<HTMLIonInputElement>()
  const phoneInputRef = React.createRef<HTMLIonInputElement>()
  const firstNameInputRef = React.createRef<HTMLIonInputElement>()
  const lastNameInputRef = React.createRef<HTMLIonInputElement>()
  const cityInputRef = React.createRef<HTMLIonInputElement>()

  const [showUIDAlert, setShowUIDAlert] = useState(false)
  /* Functions */

  async function handleEnterPress(e: any, field: React.RefObject<HTMLIonInputElement>) {
    if (e.key === "Enter") {
      if (field === emailInputRef) {
        if (countryCodeInputRef.current) countryCodeInputRef.current.setFocus()
      }
      else if (field === countryCodeInputRef) {
        if (phoneInputRef.current) phoneInputRef.current.setFocus()
      }
      else if (field === phoneInputRef) {
        if (firstNameInputRef.current) firstNameInputRef.current.setFocus()
      }
      else if (field === firstNameInputRef) {
        if (lastNameInputRef.current) lastNameInputRef.current.setFocus()
      }
      else if (field === lastNameInputRef) {
        if (cityInputRef.current) cityInputRef.current.setFocus()
      }
      else if (field === cityInputRef) {
        if (cityInputRef.current) cityInputRef.current.setBlur()
      }
    }
  }

  function toTitleCase(str: string) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  async function loadCurrentDetails() {
    let user = await getCurrentUser()
    setCountryCode(user.phone.slice(0, -10))
    setPhoneNumber(user.phone.slice(-10))
    setEmail(user.email)
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setCountry(user.country)
    setCity(user.city)
    setRegion(user.region)
    setBirthYear(user.birthYear)
    setBirthMonth(user.birthMonth)
    setBirthDay(user.birthDate)
    setAdmin(user.admin)
    setMynaID(user.mynaID)
    setUID(user.uid)
  }
  async function createuserEntry() {
    setBusy(true)

    if (firstName === '') {
      setShowToastMessage('Pehela naam enter kariye.')
      setShowToast(true)
    }
    else if (lastName === '') {
      setShowToastMessage('Aapka surname enter kariye.')
      setShowToast(true)
    }
    else if ((birthYear === undefined) || (birthMonth === undefined) || (birthDay === undefined)) {
      setShowToastMessage('Aapka janamdin enter kariye.')
      setShowToast(true)
    }
    else if (phoneNumber === '') {
      setShowToastMessage('Aapka phone number enter kariye.')
      setShowToast(true)
    }
    else if (city === '') {
      setShowToastMessage('Aapke sheher ka naam enter kariye.')
      setShowToast(true)
    }
    else if (region === '') {
      setShowToastMessage('Aapke raajya ka naam enter kariye.')
      setShowToast(true)
    }
    else {
      console.log('Trying to create entry')
      let currentDateObject = new Date()

      var creationResult = await addCurrentUserToDatabase(email, countryCode + phoneNumber,
        firstName, lastName, birthYear, birthMonth, birthDay, country, region, city, latitude, longitude,
        currentDateObject, admin, mynaID)

      if (creationResult === true) {
        // Login worked
        console.log("Added user to database")
        let tabBar = document.getElementById("tabBar")
        if (tabBar) {
          tabBar.style.display = "flex"
        }
        setBusy(false)
        navigationContext.navigate("/dashboard", "forward")
        window.location.reload()
        
      }
      else {
        // Catch error codes here
        setShowToastMessage('Unknown error with creating account: ' + creationResult)
        setShowToast(true)
      }
    }

    setBusy(false)
  }


  /* Lifecycle methods */
  useIonViewWillEnter(() => {
    Geolocation.getCurrentPosition().then((response) => {
      setLatitude(response.coords.latitude)
      setLongitude(response.coords.longitude)
    })
    loadCurrentDetails().then(() => {
      let tabBar = document.getElementById("tabBar")
      if (tabBar) {
        tabBar.style.display = "none"
      }
      setBusy(false)
    })
  })




  return (
    <IonPage>
      <IonHeader>
      </IonHeader>
      <IonLoading
        message="Load ho raha hai..."
        duration={0}
        isOpen={busy}
      />
      <IonContent fullscreen className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonTitle size="large">Update Profile</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="8">
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput
                enterkeyhint="next"
                placeholder="Email (agar aapke pass ho toh)"
                inputmode="email"
                value={email}
                ref={emailInputRef}
                onKeyPress={(e: any) => handleEnterPress(e, emailInputRef)}
                onIonChange={(e: any) => setEmail(e.target.value)}>
              </IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="1" />
            <IonCol size="2">
              <IonInput
                enterkeyhint="next"
                inputmode="tel"
                type="tel"
                value={countryCode}
                ref={countryCodeInputRef}
                onKeyPress={(e: any) => handleEnterPress(e, countryCodeInputRef)}
                onIonChange={(e: any) => setCountryCode(e.target.value)}>
              </IonInput>
            </IonCol>
            <IonCol>
              <IonInput
                enterkeyhint="next"
                inputmode="tel"
                value={phoneNumber}
                ref={phoneInputRef}
                onKeyPress={(e: any) => handleEnterPress(e, phoneInputRef)}
                type="number"
                placeholder="Phone number"
                onIonChange={(e: any) => setPhoneNumber(e.target.value)}>
              </IonInput>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonInput
                enterkeyhint="next"
                placeholder="Pehela naam"
                value={firstName}
                ref={firstNameInputRef}
                onKeyPress={(e: any) => handleEnterPress(e, firstNameInputRef)}
                onIonChange={(e: any) => setFirstName(toTitleCase(e.target.value))}>
              </IonInput>
            </IonCol>
            <IonCol>
              <IonInput

                enterkeyhint="next"
                placeholder="Surname"
                value={lastName}
                ref={lastNameInputRef}
                onKeyPress={(e: any) => handleEnterPress(e, lastNameInputRef)}
                onIonChange={(e: any) => setLastName(toTitleCase(e.target.value))}>
              </IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput

                enterkeyhint="send"
                placeholder="City ka Naam"
                ref={cityInputRef}
                onKeyPress={(e: any) => handleEnterPress(e, cityInputRef)}
                value={city}
                onIonChange={(e: any) => setCity(toTitleCase(e.target.value))}>
              </IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <CountryDropdown
              value={country}
              id="countrySelector"
              onChange={(val: any) => setCountry(val)} />
            <RegionDropdown
              country={country}
              value={region}
              id="regionSelector"
              onChange={(val: any) => setRegion(val)} />
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>
                Janamdin enter kariye:
            </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Calendar
                calendarType="US"
                minDate={new Date(1900, 0, 1)}
                maxDate={new Date()}
                onChange={(e: any) => { setBirthYear(e.getUTCFullYear()); setBirthMonth(e.getUTCMonth()); setBirthDay(e.getUTCDate()) }}
                value={((birthYear !== undefined) && (birthMonth !== undefined) && (birthDay !== undefined)) ? new Date(birthYear, birthMonth, birthDay) : undefined}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                type="submit"
                onClick={createuserEntry}>
                Update Profile
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton onClick={() => setShowUIDAlert(true)}>
                Show App UID
</IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
              hidden={!admin}
              href="/admin"
              >
                Admin
</IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonRouterLink href="/dashboard">Vaapas Jaaye</IonRouterLink>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonAlert
          isOpen={showUIDAlert}
          onDidDismiss={() => setShowUIDAlert(false)}
          // cssClass='my-custom-class'
          // header={'Alert'}
          // subHeader={'Subtitle'}
          message={"Your app UId is " + uid}
          buttons={['OK']}
        />

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          header={'Entry Karne mein Dikkat'}
          message={toastMessage}
          buttons={['Retry']}
          duration={4000}
        />
      </IonContent>
    </IonPage>
  );
};

export default UpdateProfileInfo;
