import "firebase/firestore"

import { firestore, storage } from './firebaseConfig'

import firebase from 'firebase/app'

// Class for storing all the information from a given doctor
export class DoctorData {
  firstName: string
  lastName: string
  fullName: string
  id: string
  phoneNumber: string
  email: string
  city: string
  state: string
  country: string
  availability: string

  constructor(doctorDataObject: firebase.firestore.DocumentData) {
    let data = doctorDataObject.data();
    this.firstName = data.name.first;
    this.lastName = data.name.last;
    this.phoneNumber = data.contact.phoneNumber;
    this.email = data.contact.email;
    this.city = data.location.city;
    this.state = data.location.state;
    this.country = data.location.country;
    this.availability = data.availability;
    this.fullName = 'Dr. ' + this.firstName +
      ' ' +
      this.lastName
    this.id = doctorDataObject.id;
  }
}

// Class for storing the information for a given specialty
export class DoctorSpecialtyData {
  description: string
  title: string
  id: string

  constructor(specialtyDataObject: firebase.firestore.DocumentData) {
    let data = specialtyDataObject.data()
    this.id = specialtyDataObject.id
    this.description = data.description
    this.title = data.title
  }
}

// Given a specialty object, return doctor objects for every doctor in that specialty
export async function getDoctorsFromSpecialty(specialty: DoctorSpecialtyData) {
  let doctorRef = firestore.collection('doctors').doc(specialty.id).collection('doctors')
  let doctors: DoctorData[] = []
  await doctorRef.get().then((querySnapshot) => {

    querySnapshot.forEach((doctorDoc) => {
      let newDoctor = new DoctorData(doctorDoc)
      doctors.push(newDoctor)

    }
    )
  }).catch((error) => {
    console.log('Error getting doctors')
    doctors = []
  })

  return doctors

}

// Get specialty objects for every specialty
export async function getDoctorSpecialties() {
  let specialtyRef = firestore.collection('doctors')
  let specialties: DoctorSpecialtyData[] = []

  await specialtyRef.get().then((querySnapshot) => {

    querySnapshot.forEach((specialtyDoc) => {
      let newSpecialty = new DoctorSpecialtyData(specialtyDoc)
      specialties.push(newSpecialty)
    }
    )
  }).catch((error) => {
    console.log('Error getting specialties')
    specialties = []
  })

  return specialties

}

// Get the image download link for a doctor, using their specialty to find it
export async function getDoctorImageLink(specialty: DoctorSpecialtyData, doctor: DoctorData) {
  try {
    return await storage.ref().child('doctors/images/')
      .child(specialty.id).child(doctor.id + '.jpg')
      .getDownloadURL()
  }
  catch (error) {
    if (error.code === 'storage/object-not-found') {
      return await storage.ref().child('doctors/images/silhouette.jpg').getDownloadURL()
    }
    else {
      return false
    }
  }

}

// Create a new doctor in Firestore
export async function createDoctor(
  doctorSpecialty: DoctorSpecialtyData,
  doctorFirstName: string,
  doctorLastName: string,
  doctorEmail: string,
  doctorPhone: string,
  doctorCity: string,
  doctorState: string,
  doctorCountry: string,
  doctorAvailability: string,
  doctorImage: File) {
  try {
    let doctorRef = firestore.collection('doctors').doc(doctorSpecialty.id).collection('doctors').doc()
    doctorRef.set({
      availability: doctorAvailability,
      contact: {
        email: doctorEmail,
        phoneNumber: doctorPhone
      },
      location: {
        city: doctorCity,
        state: doctorState,
        country: doctorCountry
      },
      name: {
        first: doctorFirstName,
        last: doctorLastName
      }
    })

    if (doctorImage.size > 0) {
      let imageRef = storage.ref().child('doctors/images/').child(doctorSpecialty.id).child(doctorRef.id + '.jpg')
      await imageRef.put(doctorImage)
    }


    return true
  }
  catch (error) {
    return error.code
  }

}

// Get a specialty object from its readable ID
export async function getSpecialtyFromID(id: string) {
  try {
    let specialtyDoc = await firestore.collection('doctors').doc(id).get()
    return new DoctorSpecialtyData(specialtyDoc)
  }
  catch (error) {
    console.log('Error getting specialty from ID')

    return null
  }
}
