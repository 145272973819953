export const chemistsData = [
  {
    "name": "Jan Aushadhi Kendra",
    "district": "Mumbai City",
    "address": "Shop No. 7, Nav-Bharat CHS, A Wing, N.S. Phadke Marg, Juhu Ln, Andheri West, Mumbai, Maharashtra",
    "zipCode": "400058",
    "timing": "24/7",
    "phoneNumber": "099300 92398",
    "contact": "",
    "storeCode": ""
  },
  {
    "name": "Jan Aushadhi Store",
    "district": "Mumbai City",
    "address": "Ghatkopar Seva Sangh, Near Chirag Nagar Police Station, Opp. Petrol pump,, LBS Marg, Ghatkopar (West), Mumbai, Maharashtra",
    "zipCode": "",
    "timing": "24/7",
    "phoneNumber": "022 2512 0382",
    "contact": "",
    "storeCode": ""
  },
  {
    "name": "Wellness Forever",
    "district": "Mumbai City",
    "address": "Shop No. 1&2, Medicare Hospital, Andheri - Kurla Rd, Marol Naka, Andheri East, Mumbai, Maharashtra",
    "zipCode": "400059",
    "timing": "24/7",
    "phoneNumber": "",
    "contact": "",
    "storeCode": ""
  },
  {
    "name": "The Parel Chemist",
    "district": "Mumbai City",
    "address": "Lahar Bros. Mansion, Shop no 6,7, opp. to KEM Hospital, Parel East, Parel, Mumbai, Maharashtra",
    "zipCode": "400012",
    "timing": "24/7",
    "phoneNumber": "022 2411 0411",
    "contact": "",
    "storeCode": ""
  },
  {
    "name": "Mahalaxmi Medical & General Stores",
    "district": "Mumbai City",
    "address": "NumShopber 15-B, Deepmala CHSL Malwani Number 1, Marve Rd, Malad West, Mumbai, Maharashtra",
    "zipCode": "400095",
    "timing": "24/7",
    "phoneNumber": "022 2866 0778",
    "contact": "",
    "storeCode": ""
  },
  {
    "name": "National Chemist",
    "district": "Mumbai City",
    "address": "2345, Dr Ernest Borges Rd, Opp to KMS Hospital, Parel East, Parel, Mumbai, Maharashtra",
    "zipCode": "400012",
    "timing": "24/7",
    "phoneNumber": "022 4068 0742",
    "contact": "",
    "storeCode": ""
  },
  {
    "name": "OM Medical & General Stores",
    "district": "Mumbai City",
    "address": "1, Marve Rd, Malad, BMC Colony, Rathodi, Malad West, Mumbai, Maharashtra",
    "zipCode": "400095",
    "timing": "24/7",
    "phoneNumber": "",
    "contact": "",
    "storeCode": ""
  },
  {
    "name": "Shree Gajanan Medical Stores",
    "district": "Mumbai City",
    "address": "Alankar, Shop no 5&6, Sulochana Sheti Marg, Sion West,, opp Mata Lachmi Park, Mumbai, Maharashtra",
    "zipCode": "400022",
    "timing": "24/7",
    "phoneNumber": "022 2407 3737",
    "contact": "",
    "storeCode": ""
  },
  {
    "name": "Pradhan Mantri Bharatiya Jan Aushadhi Kendra",
    "district": "Mumbai City",
    "address": "Shop No. 9, Dhvani Building, Next To Sumangal Hospital, Old Link Rd, opp. to Thakkar Dairy / Corner Sweets, Ganesh Nagar, Kandivali West, Mumbai, Maharashtra",
    "zipCode": "400067",
    "timing": "24/7",
    "phoneNumber": "",
    "contact": "",
    "storeCode": ""
  },
  {
    "name": "JAN AUSHADHI MEDICAL STORE",
    "district": "Mumbai City",
    "address": "Bihari Tekdi Rd, Bihari Tekdi, Gangubai Chawl, Janata Nagar, Kandivali East, Mumbai, Maharashtra",
    "zipCode": "400101",
    "timing": "24/7",
    "phoneNumber": "",
    "contact": "",
    "storeCode": ""
  },
  {
    "name": "Jan Aushadhi Store",
    "district": "Mumbai City",
    "address": "Shop Number A9,Ground Floor,Sanjoba CHSLtd,Gorai 2,Plot Number 88,RSC37, Borivali(West), Mumbai, Maharashtra",
    "zipCode": "413504",
    "timing": "24/7",
    "phoneNumber": "097027 81951",
    "contact": "",
    "storeCode": ""
  }
]
