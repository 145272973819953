import "firebase/firestore"

import { firestore } from './firebaseConfig'
import { UserData } from './users'

// Gets the user's period days. Creates a new entry if
// one does not exist
export async function getOrCreateUserPeriodDays(user: UserData) {
  if (!user.periodDays) {
    // Create a new videoFeedback map

    let userRef = firestore.collection('users').doc(user.uid)

    let periodDays: { [key: string]: boolean } = {}

    await userRef.update({
      periodDays: periodDays
    })
    return null
  }
  else {

    return user.periodDays
  }

}

// Gets the user's period days. Creates a new entry if
// one does not exist
export async function toggleUserPeriodDay(user: UserData, date: string) {

    // Create a new videoFeedback map

    let userRef = firestore.collection('users').doc(user.uid)

    if (user.periodDays.hasOwnProperty(date)) {
      delete user.periodDays[date]
    }
    else {
      user.periodDays[date] = true
    }

    await userRef.update({
      periodDays: user.periodDays
    })
    return user.periodDays


}

// Gets most recent period day logged
export function getLatestPeriodDay(user: UserData) {
  if (!user.periodDays) {
    return null
  }
  else {
    let sortedDates = Object.keys(user.periodDays).sort()
    return new Date(sortedDates[sortedDates.length - 1])
  }

}
