import "firebase/analytics"

import { getCurrentUser, getUserUIDWithAuth } from './users'

import { VideoData, VideoCategoryData } from './videos'
import { analytics } from './firebaseConfig'


// Make sure the current user session is tracked with the right identifier
export function setTrackingID() {
  analytics.setUserId(getUserUIDWithAuth())
}

// Make sure the current user properties are set properly
export async function setTrackingProperties() {
  let user = await getCurrentUser();
  let mynaID = user.mynaID;
  let uid = user.uid;
  let city = user.city;
  let today = new Date();
  let age = today.getFullYear() - user.birthYear;
  let monthDifference = today.getMonth() - user.birthMonth;
  if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < user.birthDate)) {
    age--;
  }
  let registrationDate = new Date(user.registrationYear, user.registrationMonth, user.registrationDate)


  analytics.setUserProperties({
    city: city,
    age: age,
    registrationDate: registrationDate.toISOString(),
    mynaID: mynaID,
    uid: uid
  })

}

// Track that the user has changed the visible tip
export function trackTipChanged() {
  analytics.logEvent('tipChanged')
}

// Track that the user has pressed a Myna engagement link
export function trackFollowLinkPressed(type: string) {
  analytics.logEvent('followLinkPressed', {
    type: type
  })
}

// Track that the user has pressed a Myna engagement link
export function trackContactLinkPressed(type: string) {
  analytics.logEvent('contactLinkPressed', {
    type: type
  })
}

// Track that a user has pressed a button to the health shop
export function trackShopButtonPressed() {
  analytics.logEvent('shopButtonPressed')
}

// Track that the user logged in with email
export function trackUserLoggedInWithEmail() {
  analytics.logEvent('loggedIn', { method: 'email' })
}

// Track that the user logged in with phone
export function trackUserLoggedInWithPhoneNumber() {
  analytics.logEvent('loggedIn', { method: 'phone' })
}

// Track that the user signed up with phone
export function trackUserRegisteredWithPhoneNumber() {
  analytics.logEvent('registered', { method: 'phone' })
}

// Track that the user signed up with email
export function trackUserRegisteredWithEmail() {
  analytics.logEvent('registered', { method: 'email' })
}

// Track that the user logged out
export function trackUserLoggedOut() {
  analytics.logEvent('loggedOut')
}

// Track that the user provided feedback for a video
export function trackVideoFeedback(video: VideoData, feedback: string) {
  analytics.logEvent('videoFeedbacked', {
    videoID: video.id,
    feedback: feedback
  })
}

// Track that the user watched a video
export function trackVideoWatched(video: VideoData) {
  analytics.logEvent('videoWatched', {
    videoID: video.id
  })
}

// Track that the user shared a video
export function trackVideoShared(video: VideoData) {
  analytics.logEvent('videoShared', {
    videoID: video.id
  })
}

// Track that the user clicked on a quiz
export function trackQuizTaken(category: VideoCategoryData) {
  analytics.logEvent('quizTaken', {
    categoryID: category.id
  })
}

// Track that the user interacted with the period calendar
export function trackPeriodDayToggled() {
  analytics.logEvent('periodDayToggled')
}

// Track the user's app version
export function trackAppVersion() {
  analytics.logEvent('appVersionLogged', {
    appVersion: 'Beta V4.9'
  })
}

// Track that the user watched a video
export function trackHelpButtonPressed() {
  analytics.logEvent('helpButtonPressed')
}

// Track that the user watched a video
export function trackMenuOpened() {
  analytics.logEvent('menuOpened')
}

// Track sharing the app
export function trackShareButtonPressed(source: string) {
  analytics.logEvent('shareButtonPressed', {
    source: source
  })
}

// Track sharing the app
export function trackChatMessageSent(isAnyonymous: boolean, messageBoard: string) {
  analytics.logEvent('chatMessageSent', {
    isAnyonymous: isAnyonymous,
    messageBoard: messageBoard
  })
}

// Track campaign conversion
export function trackCampaignConverted(campaignID: string) {
  analytics.logEvent('campaignConverted', {
    campaignID: campaignID
  })
}
