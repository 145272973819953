/*React imports*/
import React, {
  useState
} from 'react';

import ReactDOM from 'react-dom'

/* Ionic component imports */
import {
  IonContent,
  IonPage,
  IonTitle,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonTabButton,
  IonIcon,
  IonModal,
  IonButton,
  IonToast,
  IonCard,
  IonHeader,
  IonToolbar
} from '@ionic/react';

import HeaderSystem from '../components/HeaderSystem'

/*Ionic React lifecycle imports*/
import {
  useIonViewDidEnter,
  useIonViewWillEnter
} from '@ionic/react'

/*Third party plugin imports*/
import Calendar from 'react-calendar'


/*Helper imports*/
import {
  getStoresFromCategory
} from '../helpers/stores'

import {
  getOrCreateUserPeriodDays,
  toggleUserPeriodDay,
  getLatestPeriodDay
} from '../helpers/periodTracking'

import {
  getCurrentUser
} from '../helpers/users'

/*Helper imports*/
import {
  trackPeriodDayToggled
} from '../helpers/tracking'

/*Image, Data, and Icon imports*/

import {
  navigate,
  help,
  call,
  arrowBackOutline
} from 'ionicons/icons';


const HealthInfo: React.FC = () => {
  /* Contexts */

  /* States and setters */
  const [busy, setBusy] = useState(true)
  const [showStoresModal, setShowStoresModal] = useState(false)
  const [periodDays, setPeriodDays] = useState<{ [key: string]: boolean }>({ 'test': true })
  const [latestPeriodDay, setLatestPeriodDay] = useState<Date | null>(null)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastHeader, setToastHeader] = useState('')


  /* Functions */

  async function selectDay(date: string) {
    setBusy(true)
    trackPeriodDayToggled();
    let user = await getCurrentUser()
    let newPeriodDays = await toggleUserPeriodDay(user, date)
    setPeriodDays(newPeriodDays)
    setLatestPeriodDay(getLatestPeriodDay(user))
    setBusy(false)
  }

  function dayIsPeriodDay(date: string) {
    let returnVal: boolean

    if (periodDays[date]) {
      returnVal = periodDays[date]
    }
    else {
      returnVal = false
    }

    return returnVal
  }

  // Grab all the store objects and render them to the table
  async function loadStores() {
    setBusy(true)

    let storeList = await getStoresFromCategory('chemists').then((storeList) => {
      return storeList
    })

    let rows: JSX.Element[] = []

    let i
    for (i = 0; i < storeList.length; i++) {

      let store = storeList[i]

      rows.push(
        <IonRow class="ion-nowrap" key={store.id}>
          <IonCol size="5">
            <IonText>{store.name}</IonText>
          </IonCol>
          <IonCol size="2">
            <IonTabButton
              class="functionalTabButton"
              href={"tel:" + store.phoneNumber}
              style={(store.phoneNumber === '') ? { display: 'none' } : {}}
            >
              <IonIcon
                icon={call}
              />
            </IonTabButton>
          </IonCol>
          <IonCol size="2">
            <IonTabButton
              class="functionalTabButton"
              href={"http://maps.google.com/?q=" + store.address}
              style={(store.address === '') ? { display: 'none' } : {}}
            >
              <IonIcon
                icon={navigate}
              />
            </IonTabButton>
          </IonCol>
          <IonCol size="3">
            <IonText>{store.district}</IonText>
          </IonCol>
          <IonCol size="3">
            <IonText>{store.zipCode}</IonText>
          </IonCol>
          <IonCol size="3">
            <IonText>{store.timing}</IonText>
          </IonCol>
          <IonCol size="4">
            <IonText class="selectableText">{store.phoneNumber}</IonText>
          </IonCol>
          <IonCol size="12">
            <IonText class="selectableText">{store.address}</IonText>
          </IonCol>
        </IonRow>
      )
    }

    let storesRowsContainer = document.getElementById("storesRows")
    if (storesRowsContainer !== null) {
      ReactDOM.render(rows, storesRowsContainer)
    }

    setBusy(false)
  }

  /* Lifecycle methods */
  useIonViewWillEnter(() => {
    setBusy(true)
  })


  useIonViewDidEnter(() => {
    window.history.pushState({}, '', window.top.location.pathname + window.top.location.search)
    loadStores();
    getCurrentUser().then((user) => {
      getOrCreateUserPeriodDays(user).then((result) => {
        if (result) {
          setPeriodDays(result)
          setLatestPeriodDay(getLatestPeriodDay(user))
        }
        setBusy(false)
      })
    })
  })

  return (
    <IonPage>
      <HeaderSystem pageTitle="Sehat" />
      <IonLoading
        message="Load ho raha hai..."
        duration={0}
        isOpen={busy}
      />
      <IonContent fullscreen className="ion-padding">
        <IonGrid class="contentGrid">
          <IonRow>
            <IonCol>
              <IonTitle size="small">Period Tracker</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>Aapke period ka har din yahan click kariye </IonText>
              <IonIcon
                src={help}
                class="circleIconButton"
                onClick={() => {
                  setToastHeader('Aapke periods agle mahine kab aayenge?');
                  setToastMessage('Yahan har mahine track karne se yaad rakh sakte hain!');
                  setShowToast(true)
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <Calendar
                className="periodTrackingCalendar"
                calendarType="US"
                minDate={new Date(2020, 0, 1)}
                maxDate={new Date(((new Date()).getFullYear() + 1), 0, 1)}
                onClickDay={(e: any) => selectDay(e.toISOString())}
                tileClassName={({ date }) => dayIsPeriodDay(date.toISOString()) ? 'periodDay' : 'notPeriodDay'}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>
                Aapke pichle periods kathum hue:
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="auto">
              <IonCard>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonText class="lastOrNextPeriodDay">
                        {latestPeriodDay ?
                          latestPeriodDay.toDateString() :
                          "Apne periods upar track kariye!"}
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>
                Aapke agla periods shuru hota hai:
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="auto">
              <IonCard>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonText class="lastOrNextPeriodDay">
                        {latestPeriodDay ?
                          new Date(latestPeriodDay.getFullYear(), latestPeriodDay.getMonth(), latestPeriodDay.getDate() + 25).toDateString() :
                          "Apne periods upar track kariye!"}
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>

            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonTitle size="small">Chemist Stores</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton onClick={() => setShowStoresModal(true)}>Show Stores</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonModal
          isOpen={showStoresModal}
          onWillPresent={loadStores}
          onDidDismiss={() => setShowStoresModal(false)}
        >
          <IonContent>
            <IonHeader>
              <IonToolbar>
              <IonIcon
                icon={arrowBackOutline}
                slot="start"
                onClick={() => setShowStoresModal(false)}
                size="large"
                color="tertiary"
                />
                <IonTitle>
                  Medical Stores
          </IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonGrid
              style={{ width: "100%" }}
            >

              <IonRow>
                <IonCol>
                  <IonText>In Medical shops mein aapko dawaaiyaan bohot hi kam daam
                  par milengi. Covid ke samay ko dhyaan mein rakhte hue in medical
                shops pe call karke jaaye.</IonText>
                </IonCol>
              </IonRow>

              <div id="storesTableContainer">
                <IonRow
                  class="ion-nowrap"
                  style={{ "fontWeight": "bold" }}
                >
                  <IonCol size="5">
                    <IonText>Name</IonText>
                  </IonCol>
                  <IonCol size="2">
                    <IonText>Call</IonText>
                  </IonCol>
                  <IonCol size="2">
                    <IonText>Map</IonText>
                  </IonCol>
                  <IonCol size="3">
                    <IonText>District</IonText>
                  </IonCol>
                  <IonCol size="3">
                    <IonText>Pincode</IonText>
                  </IonCol>
                  <IonCol size="3">
                    <IonText>Timing</IonText>
                  </IonCol>
                  <IonCol size="4">
                    <IonText>Phone Number</IonText>
                  </IonCol>
                  <IonCol size="12">
                    <IonText>Address</IonText>
                  </IonCol>
                </IonRow>
                <div id="storesRows" />
              </div>
            </IonGrid>
          </IonContent>
        </IonModal>


        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          header={toastHeader}
          buttons={['Close']}
          duration={10000}
        />

      </IonContent>
    </IonPage>
  );
};

export default HealthInfo;
