import "firebase/firestore"

import firebase from 'firebase/app'
import { firestore } from './firebaseConfig'
import { getCurrentUser, getUserUIDWithAuth } from './users'

// Object class for chat message data
export class MessageData {
  id: string
  content: string
  senderID: string
  senderFirstName: string
  dateTime: Date

  constructor(messageDataObject: firebase.firestore.DocumentData) {
    let data = messageDataObject.data()
    this.content = data.content;
    this.senderID = data.senderID;
    this.senderFirstName = data.senderFirstName;
    this.dateTime = new Date(data.dateTime)
    this.id = messageDataObject.id
  }
}

// Get all video objects for a given category
export async function getPublicMessages(category: string) {
  let collectionRef = firestore.collection('publicChat').doc(category).collection('messages').orderBy('dateTime')
  let messages: MessageData[] = []

  await collectionRef.get().then((querySnapshot) => {

    querySnapshot.forEach((messageDoc) => {
      let newMessage = new MessageData(messageDoc)
      messages.push(newMessage)

    }
    )
  }).catch((error) => {
    console.log('Error getting messages')
    messages = []
  })

  return messages

}

// Get all video objects for a given category
export async function createMessage(message: string, isAnonymous: boolean, category: string) {
  let newFields = {
    content: message,
    dateTime: (new Date()).toISOString(),
    senderID: getUserUIDWithAuth(),
    senderFirstName: 'Anonymous'
  }

  if (!isAnonymous) {
    let currentUser = await getCurrentUser();
    newFields['senderFirstName'] = currentUser.firstName
  }

  // Ignore blank messages
  if (message === '') {
    return
  }

  try {
    firestore.collection('publicChat').doc(category).collection('messages').doc().set(newFields)
  }
  catch (error) {
    return error.code
  }

}
