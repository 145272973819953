import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/analytics"
import "firebase/messaging"

import firebase from 'firebase/app'



const config = {
  apiKey: "AIzaSyAU936AQsLVKff4GvI1NlPg7EHCSpgBNX0",
  authDomain: "myna-health-app.firebaseapp.com",
  databaseURL: "https://myna-health-app-default-rtdb.firebaseio.com",
  projectId: "myna-health-app",
  storageBucket: "myna-health-app.appspot.com",
  messagingSenderId: "510862234728",
  appId: "1:510862234728:web:83e6b4c7167cc7696657dd",
  measurementId: "G-6SYE2G4HMP"
};

firebase.initializeApp(config);

export const authentication = firebase.auth();
authentication.languageCode = 'hi';

export const firestore = firebase.firestore();
firestore.settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});
firestore.enablePersistence()
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      console.log(err)
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
      console.log(err)
    }
  });


export const storage = firebase.storage();
export const analytics = firebase.analytics();
