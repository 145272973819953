/*React imports*/
import React, {
  useState
} from 'react';

import {
  useLocation,
  useHistory
} from 'react-router-dom'


import ReactDOM from 'react-dom'

/* Ionic component imports */
import {
  IonContent,
  IonPage,
  IonTitle,
  IonLabel,
  IonText,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonCard,
  IonTabButton,
  IonList,
  IonItem,
  IonIcon,
  IonToast,
  IonImg,
  IonCardContent,
  IonModal,
  IonCheckbox,
  IonToolbar,
  IonHeader
} from '@ionic/react';

import HeaderSystem from '../components/HeaderSystem'

/*Ionic React lifecycle imports*/
import {
  useIonViewDidEnter,
  useIonViewWillEnter
} from '@ionic/react'

/*Third party plugin imports*/
import Collapsible from "react-collapsible"

/*Helper imports*/
import {
  trackVideoFeedback,
  trackVideoWatched,
  trackQuizTaken,
  trackVideoShared
} from '../helpers/tracking'

import {
  UserData,
  getCurrentUser,
  getUserUIDWithAuth
} from "../helpers/users"

import {
  getVideosFromCategory,
  getVideoCategories,
  VideoData,
  VideoCategoryData,
  getCategoryFromID,
  getOrCreateUserVideoFeedback,
  setUserVideoFeedback,
  getOrCreateUserVideoWatched,
  toggleUserVideoWatched
}
  from '../helpers/videos'

/*Image, Data, and Icon imports*/

import {
  thumbsUp,
  thumbsDown,
  logoYoutube,
  scan,
  chevronDown,
  chevronBack,
  help,
  playCircle,
  shareSocial,
  arrowBackOutline
} from 'ionicons/icons';

const Videos: React.FC = () => {
  /* Contexts */

  /* Query Parameters */
  const query = new URLSearchParams(useLocation().search);
  const pathName = useLocation().pathname
  const pageHistory = useHistory();

  const requestedCategory = query.get('category')
  const requestedVideo = query.get('video')

  /* States and setters */
  const [busy, setBusy] = useState(true)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastHeader, setToastHeader] = useState('')

  const [currentCategory, setCurrentCategory] = useState<VideoCategoryData>()
  const [currentVideo, setCurrentVideo] = useState<VideoData>()

  const [showVideoModal, setShowVideoModal] = useState(false)




  /* Functions */
  function updateQueryString(parameter: string, value: string | null) {
    if (value) {
      query.set(parameter, value);
    }
    else {
      query.delete(parameter)
    }

    pageHistory.push(pathName + '?' + query.toString())
    window.history.pushState({}, '', pathName + '?' + query.toString())
  }


  async function feedbackVideo(user: UserData, video: VideoData, category: VideoCategoryData, feedback: string) {
    trackVideoFeedback(video, feedback)
    setToastMessage('Video ' + feedback + '!')

    await setUserVideoFeedback(video, user, feedback)
    await loadVideos(category, false)
    setShowToast(true)
  }

  // Grab all the video objects and render them to the view
  async function loadVideos(selectedCategory: VideoCategoryData, firstRun: boolean) {
    setBusy(true)
    setCurrentCategory(selectedCategory)

    if (!firstRun) {
      updateQueryString('category', selectedCategory.id)
      updateQueryString('video', null)
    }

    let user = await getCurrentUser()

    let videoList = await getVideosFromCategory(selectedCategory).then((videoList) => {
      return videoList
    })

    let videoCards: JSX.Element[] = []

    let i
    for (i = 0; i < videoList.length; i++) {



      let video = videoList[i]
      let feedback = await getOrCreateUserVideoFeedback(video, user)
      let videoWatched = await getOrCreateUserVideoWatched(video, user)
      let videoIsRequested = (requestedVideo === video.id) && (firstRun === true);

      videoCards.push(
        <div key={video.id}>
          <IonRow>
            <IonCol
              size="1"
            >
              <IonCheckbox
                checked={videoWatched}
                onClick={() => toggleUserVideoWatched(video, user)}
                color="tertiary"
              />
            </IonCol>
            <IonCol>
              <Collapsible
                trigger={
                  <IonItem
                    button
                  >
                    <IonIcon
                      icon={chevronDown}
                      slot="end"
                      color="tertiary"
                    />
                    <IonLabel
                      class="ion-text-wrap"
                    >
                      {video.title}
                    </IonLabel>

                  </IonItem>
                }
                triggerWhenOpen={
                  <IonItem
                    button
                  >
                    <IonIcon
                      icon={chevronBack}
                      slot="end"
                      color="tertiary"
                    />
                    <IonLabel
                      class="ion-text-wrap"
                    >
                      {video.title}
                    </IonLabel>

                  </IonItem>
                }
                open={videoIsRequested}
              >
                <IonCard class="videoCard">
                  <IonCardContent >
                    <IonGrid>
                      <IonRow>
                        <IonCol size="12">
                          <IonImg
                            onClick={() => { trackVideoWatched(video); setCurrentVideo(video); setShowVideoModal(true) }}
                            src={video.thumbnailURL}
                            alt="Thumbnail"
                          />
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol
                          onClick={() => feedbackVideo(user, video, selectedCategory, 'liked')}>
                          <IonTabButton class="functionalTabButton">
                            <IonLabel
                              color={(feedback === 'liked') ? "primary" : "tertiary"}
                            >Like</IonLabel>
                            <IonIcon
                              color={(feedback === 'liked') ? "primary" : "tertiary"}
                              icon={thumbsUp}
                            />
                          </IonTabButton>
                        </IonCol>
                        <IonCol
                          onClick={() => {
                            trackVideoWatched(video);
                            setCurrentVideo(video);
                            setShowVideoModal(true)
                          }}>
                          <IonTabButton class="functionalTabButton">
                            <IonLabel>Play</IonLabel>
                            <IonIcon
                              style={{ height: "1.75em", width: "1.75em" }}
                              icon={playCircle}
                            />

                          </IonTabButton>
                        </IonCol>
                        <IonCol onClick={() => feedbackVideo(user, video, selectedCategory, 'disliked')}>
                          <IonTabButton class="functionalTabButton">
                            <IonLabel
                              color={(feedback === 'disliked') ? "primary" : "tertiary"}
                            >Dislike</IonLabel>
                            <IonIcon
                              color={(feedback === 'disliked') ? "primary" : "tertiary"}
                              icon={thumbsDown}
                            />
                          </IonTabButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardContent>
                </IonCard>
              </Collapsible>
            </IonCol>




          </IonRow>


        </div>
      )
      if (videoIsRequested) {
        trackVideoWatched(video);
        setCurrentVideo(video);
        setShowVideoModal(true)
      }

    }

    let videosContainer = document.getElementById("videosContainer")
    if (videosContainer !== null) {
      ReactDOM.render(videoCards, videosContainer)
    }

    setBusy(false)
  }

  async function loadVideoCategories() {
    let categoryList = await getVideoCategories().then((categoryList) => {
      return categoryList
    })

    let selectOptions: JSX.Element[] = []

    let i
    for (i = 0; i < categoryList.length; i++) {

      let category = categoryList[i]
      selectOptions.push(
        <IonSelectOption value={category} key={category.id}>
          {category.title}
        </IonSelectOption>
      )
    }

    ReactDOM.render(selectOptions, document.getElementById("categorySelector"))

  }

  async function setFirstVideos() {
    // TODO in long term: Make this based off the orderIndex
    let firstCategory = await getCategoryFromID('lessonSelect')
    if (requestedCategory) {
      firstCategory = await getCategoryFromID(requestedCategory)
    }
    if (firstCategory) {
      loadVideos(firstCategory, true)
    }

  }

  /* Lifecycle methods */
  useIonViewWillEnter(() => {
    setBusy(true)
  })

  useIonViewDidEnter(() => {
    window.history.pushState({}, '', window.top.location.pathname + window.top.location.search)
    loadVideoCategories().then(() => {
      setFirstVideos()
    })

  })

  return (
    <IonPage>
      <HeaderSystem pageTitle="Lessons" />
      <IonLoading
        message="Load ho raha hai..."
        duration={0}
        isOpen={busy}
      />
      <IonContent fullscreen className="ion-padding">
        <IonGrid class="contentGrid">
          <IonRow>
            <IonCol>
              <IonSelect
                placeholder="Lesson Select Kariye"
                id="categorySelector"
                interface="popover"
                interfaceOptions={{ header: "Video Topic" }}
                onIonChange={(e) => {
                  setBusy(true);
                  loadVideos(e.detail.value, false).then(
                    () => { setBusy(false) })
                }} >
              </IonSelect>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonTitle size="small">{currentCategory ? currentCategory.title : ''}</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>
                {currentCategory ? currentCategory.description : null}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                hidden={currentCategory?.quizLink === ''}
                href={currentCategory ? currentCategory.quizLink + "/?app_uid=" + getUserUIDWithAuth() : ''}
                onClick={() => {
                  if (currentCategory) {
                    trackQuizTaken(currentCategory)
                  }
                }}
              >
                Quiz Lijiye
                </IonButton>
              <IonIcon
                src={help}
                class="circleIconButton"
                onClick={() => {
                  setToastHeader("Is section ke saare lessons dekhne ke baad, Quiz se check kariye ki aapne kitna seekha.")
                  setToastMessage('Aapka test score check kariye!');
                  setShowToast(true)
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonTitle>
                Videos
              </IonTitle>
            </IonCol>
          </IonRow>
          <IonList
            id="videosContainer"
          >
          </IonList>
        </IonGrid>

        <IonModal
          isOpen={showVideoModal}
          onWillPresent={() => {
            setBusy(true);
            updateQueryString('video', currentVideo ? currentVideo.id : '')
          }
          }

        >
          <IonContent>
            <IonHeader>
              <IonToolbar>
                <IonIcon
                  icon={arrowBackOutline}
                  slot="start"
                  onClick={() => setShowVideoModal(false)}
                  size="large"
                  color="tertiary"
                  />

                <IonTitle>
                  Watch Video
                </IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonGrid style={{ width: "100%" }}>
              <IonRow>
                <IonCol>
                  <IonText
                  >
                    <h2>{currentVideo?.title}</h2>
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonText>Full screen mein dekhne ke liye:<br /></IonText>
                </IonCol>
                <IonCol size="2" />
              </IonRow>
              <IonRow>
                <IonCol size="3" class="ion-text-right">
                  <IonText>1.</IonText>
                </IonCol>
                <IonCol class="ion-text-left">
                  <IonIcon icon={logoYoutube} />
                  <IonText>&nbsp; dabaye</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="3" class="ion-text-right">
                  <IonText>2.</IonText>
                </IonCol>
                <IonCol class="ion-text-left">
                  <IonIcon icon={scan} />
                  <IonText>&nbsp; dabaye</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="3" class="ion-text-right">
                  <IonText>3.</IonText>
                </IonCol>
                <IonCol class="ion-text-left">
                  <IonText>Apne phone ko tedha kariye</IonText>
                </IonCol>
              </IonRow>


              <IonRow justify-content-center align-items-center style={{ 'height': '50%' }}>
                <IonCol>
                  <iframe
                    onLoad={() => setBusy(false)}
                    title="Video Embed"
                    allowFullScreen
                    frameBorder="0"
                    src={currentVideo?.embedURL + '?modestbranding=1&disablekb&rel=0&vq=' + (currentCategory?.id === 'myna' ? 'large' : 'medium')}
                    allow="accelerometer; encrypted-media; gyroscope"
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol
                  onClick={() => {
                    if (currentVideo && currentCategory) {
                      trackVideoShared(currentVideo)
                      updateQueryString('category', currentCategory.id)
                      updateQueryString('video', currentVideo.id)
                      // @ts-ignore
                      if (window.navigator.share) {
                        // @ts-ignore
                        window.navigator.share({
                          title: 'Share Video!',
                          text: 'Check out this video on the Myna app: ' + currentVideo.title,
                          url: window.location.href
                        })
                      }
                    }

                  }}
                >
                  <IonTabButton
                    class="functionalTabButton"
                  >
                    <IonLabel>Share video</IonLabel>
                    <IonIcon icon={shareSocial} />
                  </IonTabButton>
                </IonCol>
              </IonRow>

            </IonGrid>
          </IonContent>
        </IonModal>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          header={toastHeader}
          buttons={['Close']}
          duration={10000}
        />
      </IonContent>
    </IonPage>
  );
};

export default Videos;
