/*React imports*/
import React, {
  useState
} from 'react';

import ReactDOM from 'react-dom'

/* Ionic component imports */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonInput,
  IonButton,
  IonToast,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonRouterLink,
  IonText,
  IonSelect,
  IonSelectOption
} from '@ionic/react';

/*Ionic React lifecycle imports*/
import {
  useIonViewDidEnter
} from '@ionic/react'

/*Third party plugin imports*/

/*Helper imports*/
import {
  DoctorSpecialtyData,
  getDoctorSpecialties,
  createDoctor
} from "../helpers/doctors"

import {
  VideoCategoryData,
  getVideoCategories,
  createVideo
} from "../helpers/videos"

import {
  exportTipsDataToFirestore
} from "../helpers/tips"

import {
  exportStoresDataToFirestore
} from "../helpers/stores"

/*Image, Data, and Icon imports*/
import {
  generalTipsData
} from "../assets/data/tipsData"
import {
  chemistsData
} from "../assets/data/storesData"


const Admin: React.FC = () => {
  /* Contexts */

  /* States and setters */
  const [busy, setBusy] = useState<boolean>(true)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Default entry toast")

  const [videoTitle, setVideoTitle] = useState('')
  const [videoYoutubeID, setVideoYoutubeID] = useState('')
  const [videoCategory, setVideoCategory] = useState<VideoCategoryData>()

  const [doctorFirstName, setDoctorFirstName] = useState('')
  const [doctorLastName, setDoctorLastName] = useState('')
  const [doctorSpecialty, setDoctorSpecialty] = useState<DoctorSpecialtyData>()
  const [doctorCity, setDoctorCity] = useState('')
  const [doctorCountry, setDoctorCountry] = useState('')
  const [doctorAvailability, setDoctorAvailability] = useState('')
  const [doctorState, setDoctorState] = useState('')
  const [doctorEmail, setDoctorEmail] = useState('')
  const [doctorPhone, setDoctorPhone] = useState('')
  const [doctorImage, setDoctorImage] = useState<File>(new File([''], 'empty.jpg'))
  const [imageInputValue, setImageInputValue] = useState('')

  /* Functions */

  // Create new video entry in database
  async function submitNewVideo() {
    setBusy(true)

    if (videoYoutubeID === '') {
      setToastMessage('Video ID was not entered')
      setShowToast(true)
    }
    else if (videoTitle === '') {
      setToastMessage('Video title was not entered')
      setShowToast(true)
    }
    else if (videoCategory === undefined) {
      setToastMessage('Video category was not selected')
      setShowToast(true)
    }
    else {

      console.log(videoCategory)
      if (videoCategory) {
        await createVideo(videoCategory, videoYoutubeID, videoTitle)
        setToastMessage('Video Entry Created')
        setShowToast(true)
        setVideoYoutubeID('')
        setVideoTitle('')
      }
    }

    setBusy(false)
  }

  // Pull in tips data from file and regenerate structure in Firestore
  async function regenerateTipsData() {
    setBusy(true)
    let result = await exportTipsDataToFirestore(generalTipsData, 'generalTips')
    if (result === true) {
      setToastMessage('Tips data generated')
    }
    else {
      setToastMessage('Error: ' + result)
    }

    setBusy(false)
    setShowToast(true)

  }

  // Pull in tips data from file and regenerate structure in Firestore
  async function regenerateStoreData() {
    setBusy(true)
    let result = await exportStoresDataToFirestore(chemistsData, 'chemists')
    if (result === true) {
      setToastMessage('Stores data generated')
    }
    else {
      setToastMessage('Error: ' + result)
    }

    setBusy(false)
    setShowToast(true)

  }

  // Create new Doctor in Firestore
  async function submitNewDoctor() {
    setBusy(true)

    if (doctorFirstName === '') {
      setToastMessage('Doctor first name was not entered')
      setShowToast(true)
    }
    else if (doctorLastName === '') {
      setToastMessage('Doctor last name was not entered')
      setShowToast(true)
    }
    else if (doctorCity === '') {
      setToastMessage('Doctor city was not entered')
      setShowToast(true)
    }
    else if (doctorState === '') {
      setToastMessage('Doctor state was not entered')
      setShowToast(true)
    }
    else if (doctorCountry === '') {
      setToastMessage('Doctor country  was not entered')
      setShowToast(true)
    }
    else if (doctorPhone === '') {
      setToastMessage('Doctor phone number was not entered')
      setShowToast(true)
    }
    else if (doctorLastName === '') {
      setToastMessage('Doctor last name was not entered')
      setShowToast(true)
    }
    else if (!doctorSpecialty) {

      setToastMessage('Specialty was not selected')
      setShowToast(true)
    }
    // else if (!doctorImage) {
    //
    //   setToastMessage('Image was not selected')
    //   setShowToast(true)
    // }
    else {

      await createDoctor(doctorSpecialty,
        doctorFirstName,
        doctorLastName,
        doctorEmail,
        doctorPhone,
        doctorCity,
        doctorState,
        doctorCountry,
        doctorAvailability,
        doctorImage)
      setToastMessage('Doctor Entry Created')
      setShowToast(true)
      setDoctorFirstName('')
      setDoctorLastName('')
      setDoctorCity('')
      setDoctorState('')
      setDoctorCountry('')
      setDoctorAvailability('')
      setDoctorPhone('')
      setImageInputValue('')
    }

    setBusy(false)

  }

  // Let user upload image for doctor
  function uploadDoctorImage(files: FileList | null) {

    if (files) {
      let file = files[0]
      if (file.size / 1024 > 200) {
        setToastMessage('Please choose an image less than 200kB')
        setShowToast(true)
        setImageInputValue('')
      }
      else {
        setDoctorImage(files[0])
      }


    }
  }

  // Generate all video categories from Firestore
  async function loadVideoCategories() {
    let categoryList = await getVideoCategories().then((categoryList) => {
      return categoryList
    })

    let selectOptions: JSX.Element[] = []

    let i
    for (i = 0; i < categoryList.length; i++) {

      let category = categoryList[i]
      selectOptions.push(
        <IonSelectOption value={category} key={category.id}>
          {category.title}
        </IonSelectOption>
      )
    }

    ReactDOM.render(selectOptions, document.getElementById("adminCategorySelector"))

  }

  // Load all specialities from Firestore
  async function loadDoctorSpecialties() {
    let specialtyList = await getDoctorSpecialties().then((specialtyList) => {
      return specialtyList
    })

    let selectOptions: JSX.Element[] = []

    let i
    for (i = 0; i < specialtyList.length; i++) {

      let specialty = specialtyList[i]
      selectOptions.push(
        <IonSelectOption value={specialty} key={specialty.id}>
          {specialty.title}
        </IonSelectOption>
      )
    }

    ReactDOM.render(selectOptions, document.getElementById("adminSpecialtySelector"))

  }

  /* Lifecycle methods */
  useIonViewDidEnter(() => {
    let tabBar = document.getElementById("tabBar")
    if (tabBar) {
      tabBar.style.display = "none"
    }
    loadDoctorSpecialties()
    loadVideoCategories().then(() => {
      setBusy(false)

      alert("PERMISSION LIYE BINA, YAHAN KUCH BHI CHANGES MAT KARIYE")

    })


  })


  return (
    <IonPage>
      <IonHeader>
      </IonHeader>
      <IonLoading
        message="Load ho raha hai..."
        duration={0}
        isOpen={busy}
      />
      <IonContent fullscreen className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonTitle size="large">Admin</IonTitle>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonTitle size="small">Add New Video</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonSelect
                placeholder="Select Video Category"
                id="adminCategorySelector"
                onIonChange={(e: any) => setVideoCategory(e.detail.value)}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-right" size="4">
              <IonText>
                Enter Video Title:
              </IonText>
            </IonCol>
            <IonCol>
              <IonInput
                value={videoTitle}
                enterkeyhint="next"
                placeholder="e.g. How to Check for Lumps"
                onIonChange={(e: any) => setVideoTitle(e.target.value)}>
              </IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-right" size="4">
              <IonText>
                Enter Youtube ID (from video URL):
              </IonText>
            </IonCol>
            <IonCol>
              <IonInput
                value={videoYoutubeID}
                enterkeyhint="next"
                placeholder="e.g. QRGCPxVkyxA"
                onIonChange={(e: any) => setVideoYoutubeID(e.target.value)}>
              </IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                onClick={submitNewVideo}>
                Create Video
          </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonTitle size="small">Add New Doctor</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonSelect
                placeholder="Select Doctor Specialty"
                id="adminSpecialtySelector"
                onIonChange={(e: any) => setDoctorSpecialty(e.detail.value)}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonInput
                value={doctorFirstName}
                enterkeyhint="next"
                placeholder="Enter First Name"
                onIonChange={(e: any) => setDoctorFirstName(e.target.value)}>
              </IonInput>
            </IonCol>
            <IonCol>
              <IonInput
                value={doctorLastName}
                enterkeyhint="next"
                placeholder="Enter Last Name"
                onIonChange={(e: any) => setDoctorLastName(e.target.value)}>
              </IonInput>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonInput
                value={doctorCity}
                enterkeyhint="next"
                placeholder="Enter City"
                onIonChange={(e: any) => setDoctorCity(e.target.value)}>
              </IonInput>
            </IonCol>
            <IonCol>
              <IonInput
                value={doctorState}
                enterkeyhint="next"
                placeholder="Enter State"
                onIonChange={(e: any) => setDoctorState(e.target.value)}>
              </IonInput>
            </IonCol>
            <IonCol>
              <IonInput
                value={doctorCountry}
                enterkeyhint="next"
                placeholder="Enter Country"
                onIonChange={(e: any) => setDoctorCountry(e.target.value)}>
              </IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-right" size="4">
              <IonText>
                Doctor email address:
              </IonText>
            </IonCol>
            <IonCol>
              <IonInput
                value={doctorEmail}
                enterkeyhint="next"
                placeholder="e.g. doctor@hospital.com"
                onIonChange={(e: any) => setDoctorEmail(e.target.value)}>
              </IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-right" size="4">
              <IonText>
                Doctor phone number (include country code):
              </IonText>
            </IonCol>
            <IonCol>
              <IonInput
                value={doctorPhone}
                enterkeyhint="next"
                placeholder="e.g. +911234567890"
                onIonChange={(e: any) => setDoctorPhone(e.target.value)}>
              </IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-right" size="4">
              <IonText>
                Doctor availability:
              </IonText>
            </IonCol>
            <IonCol class="ion-text-left">
              <IonInput
                value={doctorAvailability}
                enterkeyhint="next"
                placeholder="e.g. Mondays and Tuesdays 7am-3pm"
                onIonChange={(e: any) => setDoctorAvailability(e.target.value)}>
              </IonInput>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-right" size="4">
              <IonText>
                Doctor Image:
              </IonText>
            </IonCol>
            <IonCol>
              <input
                type="file"
                accept=".jpg"
                value={imageInputValue}
                onChange={e => {
                  setImageInputValue(e.target.value);
                  uploadDoctorImage(e.target.files)
                }
                }
              >

              </input>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                onClick={submitNewDoctor}>
                Create Doctor
          </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                onClick={regenerateTipsData}
              >
                Regenerate Tips Data
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                onClick={regenerateStoreData}
              >
                Regenerate Store Data
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonRouterLink href="/dashboard">Vaapas Jaaye</IonRouterLink>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          buttons={['Retry']}
          duration={2000}
        />






      </IonContent>
    </IonPage>
  );
};

export default Admin;
