import "firebase/messaging"

import firebase from 'firebase/app'

// Register the current user for notifications
export async function registerNotificationToken() {
  const messaging = firebase.messaging();
  return await messaging.getToken({ vapidKey: 'BHK_BRY9QSpsPoBZAbR9dBt_v_5ELfkaHt_pnjguyjTzi4ELYXLUn_oaI8WgWYWUmJeN1WIFAFrmXIaCGzEWZvg' })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        return currentToken;
        // ...
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');

        return '';
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      if (err.code === 'messaging/permission-blocked') {
        alert("Please enable push notifications for this app so we can send you info! On Android go to Settings->Apps->Chrome->Notifications and turn on mynamobile.com")
      }
      return '';
      // ...
    });

}
