/*React imports*/
import React, {
  useState,
  useContext
} from 'react';

import {
  useLocation
} from 'react-router-dom'

/* Ionic component imports */
import {
  IonContent,
  IonPage,
  IonIcon,
  IonTitle,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonTabButton,
  IonText,
  IonCard,
  IonToast,
  NavContext,
  IonSelect,
  IonSelectOption
} from '@ionic/react';

import HeaderSystem from '../components/HeaderSystem'

/*Ionic React lifecycle imports*/
import {
  useIonViewWillEnter
} from '@ionic/react'

/*Third party plugin imports*/
import firebase from 'firebase/app'
import "firebase/messaging"

/*Helper imports*/
import {
  registerNotificationToken
} from '../helpers/notifications'

import {
  getRandomTip,
  getOrCreateCurrentUserTip,
  TipData,
  setUserCurrentTipIndex,
  getNextTip,
  getPreviousTip,
  getTipByIndex
} from '../helpers/tips'

import {
  setTrackingID,
  setTrackingProperties,
  trackTipChanged,
  trackShopButtonPressed,
  trackFollowLinkPressed,
  trackContactLinkPressed,
  trackAppVersion
} from '../helpers/tracking'

import {
  getCurrentUser,
  UserData,
  setCurrentUserNotificationToken,
  checkIfUserExists,
  getUserUIDWithAuth,
  logoutAndDeleteUserWithAuth
} from '../helpers/users'

import {
  getLivestream,
  LivestreamData
} from '../helpers/videos'

/*Image, Data, and Icon imports*/
import DashboardGirls from "../assets/images/dashboard-girls.png"

import {
  logoFacebook,
  logoInstagram,
  call,
  earth,
  logoYoutube,
  shuffle,
  playSkipForward,
  playSkipBack,
  help,
  chatboxEllipses
} from 'ionicons/icons'


const Dashboard: React.FC = () => {
  /* Contexts */
  const navigationContext = useContext(NavContext)

  /* Query Parameters */
  const query = new URLSearchParams(useLocation().search);

  const requestedTipIndex = query.get('tip')

  /* States and setters */
  const [busy, setBusy] = useState(true)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  const [user, setUser] = useState<UserData>()
  const [tip, setTip] = useState<TipData>()
  const [livestream, setLivestream] = useState<LivestreamData>()

  /* Functions */
  async function loadTip(presetTipIndex: string|null, currentUser: UserData, type: string) {
    setBusy(true)

    if (presetTipIndex) {
      console.log('Got custom tip ', presetTipIndex)
      let newTip = await getTipByIndex('generalTips', parseInt(presetTipIndex))
      if (newTip) {
        setUserCurrentTipIndex(newTip, currentUser)
        setUser(await getCurrentUser())
        setTip(newTip)
      }
    }

    else {
      if (type === 'current') {
        setTip(await getOrCreateCurrentUserTip('generalTips', currentUser))
      }
      else if (type === 'random') {
        let newTip = await getRandomTip('generalTips')
        if (newTip) {
          setUserCurrentTipIndex(newTip, currentUser)
          setUser(await getCurrentUser())
          setTip(newTip)
        }
      }
      else if (type === 'previous') {
        if (tip) {
          let newTip = await getPreviousTip('generalTips', tip)
          if (newTip) {
            setUserCurrentTipIndex(newTip, currentUser)
            setUser(await getCurrentUser())
            setTip(newTip)
          }
        }
      }
      else if (type === 'next') {
        if (tip) {
          let newTip = await getNextTip('generalTips', tip)
          if (newTip) {
            setUserCurrentTipIndex(newTip, currentUser)
            setUser(await getCurrentUser())
            setTip(newTip)
          }
        }
      }

      if (type !== 'current') {
        trackTipChanged()
      }
    }



    setBusy(false)
  }

  /* Lifecycle methods */
  useIonViewWillEnter(() => {
    setBusy(true)

    window.history.pushState({}, '', window.top.location.pathname + window.top.location.search)

    checkIfUserExists(getUserUIDWithAuth()).then((result) => {
      if (result === true) {
        // A Firestore user for this Auth account exists

        // Set up analytics tracking
        setTrackingID();

        setTrackingProperties().then(() => {
          trackAppVersion()
        });

        // Notifications don't work on iOS
        if (
          [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)) {
          // This is iOS and would crash messaging
        }
        else {
          // Handle incoming messages. Called when:
          // - a message is received while the app has focus
          // - the user clicks on an app notification created by a service worker
          //   `messaging.onBackgroundMessage` handler.
          const messaging = firebase.messaging();
          messaging.onMessage((payload) => {
            alert('Notification: ' + payload.notification.body)
            // ...
          });
          registerNotificationToken().then((notificationToken) => {
            setCurrentUserNotificationToken(notificationToken).then(() => {
            })

          })
        }


        getCurrentUser().then((currentUser) => {
          // Store user for display
          setUser(currentUser);

          // Set up intercom
          let uid = currentUser.uid
          let mynaID = currentUser.mynaID
          let name = currentUser.fullName

          // @ts-ignore
          window.intercomSettings = {
            app_id: "depz4im2",
            name: name,
            mynaID: mynaID,
            user_id: uid,
            uid: uid,
            custom_launcher_selector: '#intercomChatButton'
          };
          // @ts-ignore
          window.Intercom('boot', window.intercomSettings)


          getLivestream().then((result) => {
            if (result) {
              setLivestream(result)
            }

            // Load the first tip
            loadTip(requestedTipIndex, currentUser, 'current')
            setBusy(false);
          })
        })
      }
      else {
        // If a user Firestore object doesn't exist for this Auth UID,
        // reset and delete the account from Auth

        logoutAndDeleteUserWithAuth()
        navigationContext.navigate('/landing', 'back')
      }

    })



  })

  return (
    <IonPage>
      <HeaderSystem pageTitle="Dashboard" />
      <IonLoading
        message="Load ho raha hai..."
        duration={0}
        isOpen={busy}
      />
      <IonContent fullscreen className="ion-padding">
        <IonGrid class="contentGrid">


          {/* Hello and Myna ID */}
          <IonRow>
            <IonCol>
              <IonTitle>Hello {user ? user.firstName : ''}!</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>Mera Myna ID: {user ? user.mynaID : ''} </IonText>
              <IonIcon
                src={help}
                class="circleIconButton"
                onClick={() => { setToastMessage('Ye number aapko Myna ke baaki services istemal karne ki liye zaruri hai'); setShowToast(true) }}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="8">
              <img src={DashboardGirls} alt="Myna follow your dreams" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>



          {/* Call and Chat */}
          <IonRow>
            <IonCol>
              <IonTitle>Questions?</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>
                Myna ko message ya call kariye
                </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard class="dashboardContainer selectableText">
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <div id="intercomChatButton" onClick={() => trackContactLinkPressed('message')}>
                        <IonTabButton class="functionalTabButton">
                          <IonIcon
                            icon={chatboxEllipses}
                            style={{ height: "1.75em", width: "1.75em" }}
                          />
                          <IonLabel>Private Chat</IonLabel>
                        </IonTabButton>
                      </div>
                    </IonCol>
                    <IonCol onClick={() => trackContactLinkPressed('call')}>

                      <IonTabButton class="functionalTabButton" href="tel:+912248972607">
                        <IonIcon
                          icon={call}
                          style={{ height: "1.75em", width: "1.75em" }}
                        />
                        <IonLabel>Call</IonLabel>
                      </IonTabButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>

          {/* Livestream */}
          <div hidden={livestream?.isActive ? false : true}>
            <IonRow>
              <IonCol>
                <IonTitle>
                  Aaj ka Myna Livestream
              </IonTitle>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonTabButton class="functionalTabButton"
                  href={livestream?.videoURL}
                >
                  <IonLabel>Play Livestream</IonLabel>
                  <IonIcon
                    style={{ height: "1.75em", width: "1.75em" }}
                    icon={logoYoutube}
                  />
                </IonTabButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <hr />
              </IonCol>
            </IonRow>
          </div>



          {/* Help Video */}
          <IonRow>
            <IonCol>
              <IonTitle>App ki Jaankari</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow >
            <IonCol>
              <iframe
                title="Help video"
                allowFullScreen
                frameBorder="0"
                src={'https://www.youtube.com/embed/J8YRkpsz9Bc?modestbranding=1&disablekb&rel=0'}
                allow="accelerometer; encrypted-media; gyroscope"
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>




          {/* Health Tip */}
          <IonRow>
            <IonCol>
              <IonCard class="dashboardContainer selectableText">
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonTitle>
                        Health Tip #{tip ? parseInt(tip.tipIndex) + 1 : ''}
                      </IonTitle>
                    </IonCol>
                  </IonRow>
                  <IonRow />
                  <IonRow>
                    <IonCol>
                      <IonText>
                        "{tip?.tipContent}"
              </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol onClick={() => user ? loadTip(null, user, 'previous') : ''}>
              <IonTabButton class="functionalTabButton">
                <IonLabel>
                  Pichla Tip
            </IonLabel>
                <IonIcon
                  icon={playSkipBack}
                />
              </IonTabButton>
            </IonCol>
            <IonCol onClick={() => user ? loadTip(null, user, 'random') : ''}>
              <IonTabButton class="functionalTabButton">
                <IonLabel>
                  Naya Tip
              </IonLabel>
                <IonIcon
                  icon={shuffle}
                />
              </IonTabButton>
            </IonCol>
            <IonCol onClick={() => user ? loadTip(null, user, 'next') : ''}>
              <IonTabButton class="functionalTabButton">
                <IonLabel>
                  Agla Tip
              </IonLabel>
                <IonIcon
                  icon={playSkipForward}
                />
              </IonTabButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>




          {/* Health Store */}
          <IonRow>
            <IonCol>
              <IonTitle>Myna Health Shop</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>
                Myna pad packets yahaan se lijiye
                </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonSelect
                interface="action-sheet"
                interfaceOptions={{ header: "Buy Pad Packets" }}
                onIonChange={(e) => { trackShopButtonPressed(); window.open(e.detail.value, '_self') }}
                placeholder="Buy Pad Packets"
              >
                <IonSelectOption
                  value="https://www.flipkart.com/myna-regular-wings-sanitary-pad/p/itm349bb34122801?pid=SPPGYH3Z4J9TZF9F"
                >
                  Regular Premium
                </IonSelectOption>
                <IonSelectOption
                  value="https://www.flipkart.com/myna-mahila-foundation-regular-sanitary-pad/p/itm33846e3ba0c7d?pid=SPPFUJG3YUGCHDSB"
                >
                  Regular
                </IonSelectOption>
                <IonSelectOption
                  value="https://www.flipkart.com/myna-mahilafoundation-ultra-soft-cotton-xl-sanitary-pad/p/itm72980754a7e5b?pid=SPPFGBKCGFTHREFH"
                >
                  XL Cottony
                </IonSelectOption>
                <IonSelectOption
                  value="https://www.flipkart.com/myna-mahilafoundation-ultra-soft-cotton-xxl-sanitary-pad/p/itm7e8d9b803fd17?pid=SPPFGA462KZE5THX"
                >
                  XXL Cottony
                </IonSelectOption>
                <IonSelectOption
                  value="https://www.flipkart.com/myna-mahila-foundation-maternity-sanitary-pad/p/itmfgkypqvksnku8?pid=SPPFGJU7KZDFFG58"
                >
                  Maternity
                </IonSelectOption>
                <IonSelectOption
                  value="https://www.flipkart.com/myna-premium-maternity-sanitary-pad/p/itm074383b8974a2?pid=SPPGYMZ28JCMHVP7"
                >
                  Maternity Premium
                </IonSelectOption>
              </IonSelect>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>



          {/* Follow Icons */}
          <IonRow>
            <IonCol>
              <IonTitle>
                Follow kariye
                </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow
            style={{ height: "10px" }}
          />
          <IonRow>
            <IonCol onClick={() => trackFollowLinkPressed('facebook')}>
              <IonTabButton
                class="functionalTabButton followButton"
                style={{ color: "#4267B2" }}
                href="https://www.facebook.com/mynamahila ">
                <IonIcon icon={logoFacebook} />
              </IonTabButton>
            </IonCol>
            <IonCol onClick={() => trackFollowLinkPressed('instagram')}>
              <IonTabButton
                class="functionalTabButton followButton"
                style={{ color: "#C13584" }}
                href="https://instagram.com/mynamahila">
                <IonIcon icon={logoInstagram} />
              </IonTabButton>
            </IonCol>
            <IonCol onClick={() => trackFollowLinkPressed('youtube')}>
              <IonTabButton
                class="functionalTabButton followButton"
                style={{ color: "#FF0000" }}
                href="https://www.youtube.com/channel/UCYFXH-gZLWOt9y_sszmhQNg">
                <IonIcon icon={logoYoutube} />
              </IonTabButton>
            </IonCol>
            <IonCol onClick={() => trackFollowLinkPressed('website')}>
              <IonTabButton
                class="functionalTabButton followButton"
                style={{ color: "var(--ion-color-primary)" }}
                href="https://mynamahila.com/">
                <IonIcon icon={earth} />
              </IonTabButton>
            </IonCol>
          </IonRow>



        </IonGrid>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          buttons={['Close']}
          duration={7000}
        />

      </IonContent>
    </IonPage >
  );
};

export default Dashboard;
