/*React imports*/
import React, {
  useState
} from 'react';

import ReactDOM from 'react-dom'


/* Ionic component imports */
import {
  IonContent,
  IonPage,
  IonIcon,
  IonLoading,
  IonGrid,
  IonTextarea,
  IonRow,
  IonCol,
  IonLabel,
  IonText,
  IonToast,
  IonButton,
  IonCheckbox,
  IonItem,
  IonList
} from '@ionic/react';

import HeaderSystem from '../components/HeaderSystem'

/*Ionic React lifecycle imports*/
import {
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave
} from '@ionic/react'

/*Third party plugin imports*/


/*Helper imports*/
import {
  createMessage,
  getPublicMessages
} from '../helpers/publicChat'

import {
  trackChatMessageSent
} from '../helpers/tracking'


/*Image, Data, and Icon imports*/

import {
  send,
  help
} from 'ionicons/icons'


const PublicChat: React.FC = () => {
  /* Contexts */

  /* States and setters */
  const [busy, setBusy] = useState(true)

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastHeader, setToastHeader] = useState('')

  const [newMessage, setNewMessage] = useState('')
  const [sendAnonymously, setSendAnonymously] = useState(false)

  var loadInterval: NodeJS.Timeout


  /* Functions */
  async function sendMessage() {
    trackChatMessageSent(sendAnonymously, 'general')
    createMessage(newMessage, sendAnonymously, 'general').then(() => loadMessages())
    setNewMessage('');
  }

  // Grab all the message objects and render them to the view
  async function loadMessages() {

    let messageList = await getPublicMessages('general')

    let messageItems: JSX.Element[] = []

    let i
    for (i = 0; i < messageList.length; i++) {

      let message = messageList[i]

      messageItems.push(
        <IonItem
          key={message.id}
        >
          <IonLabel class="ion-text-wrap">
            <IonText className="sender ion-float-left">
              {message.senderFirstName}
            </IonText>
            <IonText className="dateTime ion-float-right">
              {message.dateTime.toDateString()} {message.dateTime.toLocaleTimeString()}
            </IonText>

            <br />
            <IonText className="content">
              {message.content}
            </IonText>
          </IonLabel>

        </IonItem>
      )
    }

    let messagesContainer = document.getElementById("messagesContainer")
    if (messagesContainer !== null) {
      ReactDOM.render(messageItems, messagesContainer)
    }

  }



  /* Lifecycle methods */

  useIonViewWillEnter(() => {
    setBusy(true)
  })

  useIonViewDidEnter(() => {
    window.history.pushState({}, '', window.top.location.pathname + window.top.location.search)
    loadMessages().then(() => {

      // Refresh messages every second
      loadInterval =  setInterval(() => {
        loadMessages();
        console.log('Reloading messages')
      }, 1000)


      setBusy(false)
    })

  })

  useIonViewWillLeave(() => {
    // Clear message reloading to avoid wasted processing and memory
    clearInterval(loadInterval);
  })

  return (
    <IonPage>
      <HeaderSystem pageTitle="Chat with Friends" />
      <IonLoading
        message="Load ho raha hai..."
        duration={0}
        isOpen={busy}
      />
      <IonContent fullscreen className="ion-padding">
        <IonGrid class="contentGrid">
          <IonRow>
            <IonCol>
              <IonText>
                Aur Myna ladkiyon se yahan baat kariye &nbsp;
              </IonText>
              <IonIcon
                src={help}
                class="circleIconButton"
                onClick={() => {
                  setToastHeader("Aap in topics pe baat kar sakte hain:")
                  setToastMessage('(1) Apni sehat, (2) Myna ke bara mein, (3) Myna lessons, (4) Aur kuch bhi!');
                  setShowToast(true)
                }}
              />
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol
              style={{ padding: "0px" }}
              size="12"
            >
              <IonList id="messagesList">
                <div id="messagesContainer" />
              </IonList>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonTextarea
                enterkeyhint="send"
                placeholder="Type message here..."
                inputMode="text"
                value={newMessage}
                onIonChange={(e: any) => setNewMessage(e.target.value)}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    sendMessage();
                  }
                }}
                class="ion-text-left ion-text-wrap"
                autoGrow={true}
              />

            </IonCol>
            <IonCol size="3">
              <IonButton
                onClick={sendMessage}
                expand="full"
              >
                <IonIcon
                  icon={send}
                />
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol class="ion-text-right">
              <IonText>
                Naam chupaiye
          </IonText>
            </IonCol>
            <IonCol size="3" class="ion-text-left">
              <IonCheckbox
                checked={sendAnonymously}
                onIonChange={e => setSendAnonymously(e.detail.checked)}
                color="tertiary"
              />
            </IonCol>

          </IonRow>

        </IonGrid>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          header={toastHeader}
          buttons={['Close']}
          duration={10000}
        />
      </IonContent>
    </IonPage >
  );
};

export default PublicChat;
