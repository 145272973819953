import "firebase/auth"
import "firebase/firestore"

import { authentication, firestore } from './firebaseConfig'

import firebase from 'firebase/app'

// Object class for storing user data
export class UserData {
  firstName: string
  lastName: string
  fullName: string
  admin: boolean
  email: string
  phone: string
  birthYear: number
  birthMonth: number
  birthDate: number
  registrationYear: number
  registrationMonth: number
  registrationDate: number
  country: string
  region: string
  city: string
  mynaID: string
  uid: string
  videoFeedback: { [key: string]: string }
  videoWatched: { [key: string]: boolean }
  periodDays: { [key: string]: boolean }
  currentTipIndex: number


  constructor(userDataObject: firebase.firestore.DocumentData) {
    let data = userDataObject.data()
    this.firstName = data.name.first
    this.lastName = data.name.last
    this.fullName = this.firstName + ' ' + this.lastName
    this.admin = data.admin
    this.email = data.contact.email
    this.phone = data.contact.phone
    this.birthYear = data.birthday.year
    this.birthMonth = data.birthday.month
    this.birthDate = data.birthday.day
    this.registrationYear = data.registrationDateTimeUTC.year
    this.registrationMonth = data.registrationDateTimeUTC.month
    this.registrationDate = data.registrationDateTimeUTC.day
    this.country = data.location.country
    this.region = data.location.region
    this.city = data.location.city
    this.mynaID = data.mynaID
    this.uid = data.uid
    this.videoFeedback = data.videoFeedback
    this.videoWatched = data.videoWatched
    this.currentTipIndex = data.currentTipIndex
    this.periodDays = data.periodDays
  }
}

// Register user with firebase authentication
export async function registerUserWithAuth(email: string, password: string) {
  try {
    await authentication.createUserWithEmailAndPassword(email, password)
    return true
  }
  catch (error) {
    console.log(error)
    return error.code
  }
}

// Sign in  user via email with firebase authentication
export async function loginUserWithAuth(email: string, password: string) {
  try {
    await authentication.signInWithEmailAndPassword(email, password)
    return true
  }
  catch (error) {
    console.log(error)
    return error.code
  }
}

// Load the reCAPTCHA verifier into the page
export function getRecaptchaVerifierWithAuth(newRecaptchaDivID: string) {
  // The specified div below is loaded in this function so that it loads
  // the div correctly from the calling page
  return new firebase.auth.RecaptchaVerifier(newRecaptchaDivID, {
    'size': 'normal',
    'callback': (response: any) => {
      // reCAPTCHA solved
      console.log("Response from recaptcha")
    }
  });
}

// Sign in  user with phone number firebase authentication
export async function loginSendOTPWithAuth(recaptchaVerifier: firebase.auth.RecaptchaVerifier, phoneNumber: string) {
  console.log("Trying recaptcha")
  try {
    const confirmationResult = await authentication.signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
    console.log("Didn't error while signing in")
    recaptchaVerifier.clear()
    return [true, confirmationResult]
  }
  catch (error) {
    console.log(error)
    if (error.message === "RecaptchaVerifier instance has been destroyed.") {
      window.location.reload()
    }
    return [false, error.code]
  }
}

// Sign out user with firebase authentication
export async function logoutUserWithAuth() {
  try {
    await authentication.signOut()
    return true
  }
  catch (error) {
    console.log(error)
    return error.code
  }
}

// Sign out user with firebase authentication
export async function sendUserPasswordResetLinkWithAuth(email: string) {
  try {
    await authentication.sendPasswordResetEmail(email)
    return true
  }
  catch (error) {
    console.log(error)
    return error.code
  }
}

// Delete and sign out user
export async function logoutAndDeleteUserWithAuth() {
  try {
    if (authentication.currentUser) {
      authentication.currentUser.delete()
    }
    await authentication.signOut()
    return true
  }
  catch (error) {
    console.log(error)
    return error.code
  }
}

// Get the current logged in user's UID
export function getUserUIDWithAuth() {
  const user = authentication.currentUser
  if (user) {
    return user.uid
  }
  return ''

}

// Get the current user's auth email
export function getUserEmailWithAuth() {
  const user = authentication.currentUser
  if (user) {
    if (user.email) {
      return user.email
    }
  }

  return ''

}

// Get the current user's auth phone number
export function getUserPhoneNumberWithAuth() {
  const user = authentication.currentUser
  if (user) {
    if (user.phoneNumber) {
      return user.phoneNumber
    }
  }
  return ''
}

// Get the current user object
export async function getCurrentUser() {
  let userRef = firestore.collection('users').doc(getUserUIDWithAuth())
  let user: UserData

  user = new UserData(await userRef.get())

  return user

}

// Get observer for current user from firebase authentication
export function isUserLoggedIn() {
  return new Promise((resolve, reject) => {
    const unsubscribe = authentication.onAuthStateChanged(function(user) {
      if (user) {
        resolve(true)
      }
      else {
        resolve(false)
      }

      // Force this to function as a one-shot
      unsubscribe()
    })

  })
}

// Store the current user's notification token in Firestore
export async function setCurrentUserNotificationToken(notificationToken: string) {
  let userRef = firestore.collection('users').doc(getUserUIDWithAuth())

  await userRef.update({
    notificationToken: notificationToken
  })
}

// Create User entry in firebase database
export async function addCurrentUserToDatabase(email: string, phoneNumber: string,
  firstName: string, lastName: string,
  birthYear: number, birthMonth: number, birthDate: number,
  country: string, region: string, city: string, latitude: number, longitude: number,
  currentDateObject: Date, admin: boolean, mynaID: string) {
  let uid = getUserUIDWithAuth()
  try {
    await firestore.collection('users').doc(uid).set(
      {
        contact: {
          email: email,
          phone: phoneNumber
        },

        name: {
          first: firstName,
          last: lastName
        },
        birthday: {
          year: birthYear,
          month: birthMonth,
          day: birthDate
        },
        location: {
          country: country,
          region: region,
          city: city,
          latitude: latitude,
          longitude: longitude
        },
        registrationDateTimeUTC: {
          year: currentDateObject.getUTCFullYear(),
          month: currentDateObject.getUTCMonth(),
          day: currentDateObject.getUTCDate(),
          hour: currentDateObject.getUTCHours(),
          minute: currentDateObject.getUTCMinutes(),
          second: currentDateObject.getUTCSeconds()
        },
        admin: admin,
        mynaID: mynaID,
        uid: uid
      }
    )
    return true
  }
  catch (error) {
    return error.code
  }
}

// See if user entry exists in firebase database
export async function checkIfUserExists(uid: string) {
  try {
    const doc = await firestore.collection('users').doc(uid).get()
    return doc.exists
  }
  catch (error) {
    console.log('Error in finding user')
    return null

  }
}

// See if user entry exists in firebase database
export async function deleteUserFromAuth(uid: string) {
  try {
    const doc = await firestore.collection('users').doc(uid).get()
    return doc.exists
  }
  catch (error) {
    console.log('Error in finding user')
    return null

  }
}


// Saving in case a similar function is needed in the future
// export async function cleanUpBirthdayStrings() {
//   let collectionRef = firestore.collection('users')
//
//   await collectionRef.get().then((querySnapshot) => {
//
//     querySnapshot.forEach((userDoc) => {
//       let userData = userDoc.data()
//
//       if (typeof userData.birthday.day === 'string') {
//         console.log('string')
//
//         userData.birthday.day = parseInt(userData.birthday.day)
//         userData.birthday.year = parseInt(userData.birthday.year)
//         userData.birthday.month = parseInt(userData.birthday.month)
//
//         firestore.collection('users').doc(userDoc.id).update(userData)
//       }
//
//
//
//     }
//     )
//   })
//
// }
