/*React imports*/
import React, {
  useState
} from 'react';

/* Ionic component imports */
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonModal,
  IonGrid,
  IonButton,
  IonRow,
  IonCol,
  IonText
} from '@ionic/react';

/*Ionic React lifecycle imports*/

/*Third party plugin imports*/

/*Helper imports*/

import {
  trackHelpButtonPressed,
  trackMenuOpened
} from '../helpers/tracking'

/*Image, Data, and Icon imports*/
import MynaLogoWhite from "../assets/images/myna-logo-white.png"
import {
  help,
  scan,
  logoYoutube
} from 'ionicons/icons'

type Props = {
  pageTitle: string
}

const HeaderSystem: React.FC<Props> = ({ pageTitle }) => {
  /* Contexts */

  /* States and setters */
  const [showHelpModal, setShowHelpModal] = useState(false)

  return (
    <div>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{pageTitle}</IonTitle>
          <IonButtons slot="start">
            <IonMenuButton
            onClick={() => trackMenuOpened}
            >
            </IonMenuButton>
          </IonButtons>

          <IonIcon
            src={help}
            style={{padding: "10px"}}
            slot="end"
            class="circleIconButton"
            onClick={() => {
              setShowHelpModal(true);
              trackHelpButtonPressed()
            }}
          />

          <img
            slot="end"
            src={MynaLogoWhite}
            alt="Myna logo white"
            height="50px"
          />
        </IonToolbar>
      </IonHeader>

      <IonModal
        isOpen={showHelpModal}
        onDidDismiss={() => setShowHelpModal(false)}
      >
        <IonGrid style={{ width: "100%" }}>
          <IonRow>
            <IonCol>
              <IonButton
                onClick={() => setShowHelpModal(false)}
              >
                Close
</IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>Full screen mein dekhne ke liye:<br /></IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="3" class="ion-text-right">
              <IonText>1.</IonText>
            </IonCol>
            <IonCol class="ion-text-left">
              <IonIcon icon={logoYoutube} />
              <IonText>&nbsp; dabaye</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="3" class="ion-text-right">
              <IonText>2.</IonText>
            </IonCol>
            <IonCol class="ion-text-left">
              <IonIcon icon={scan} />
              <IonText>&nbsp; dabaye</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="3" class="ion-text-right">
              <IonText>3.</IonText>
            </IonCol>
            <IonCol class="ion-text-left">
              <IonText>Apne phone ko tedha kariye</IonText>
            </IonCol>
          </IonRow>

          <IonRow justify-content-center align-items-center style={{ 'height': '50%' }}>
            <IonCol>
              <iframe
              title="Help Popup"
                allowFullScreen
                frameBorder="0"
                src={'https://www.youtube.com/embed/DbvcnfJjhZI?modestbranding=1&disablekb&rel=0'}
                allow="accelerometer; encrypted-media; gyroscope"
              />
            </IonCol>
          </IonRow>

        </IonGrid>
      </IonModal>

    </div>
  )
}

export default HeaderSystem
