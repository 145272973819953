/*React imports*/
import React, {
  useState,
  useContext,
  useRef
} from 'react';

/* Ionic component imports */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonInput,
  IonButton,
  IonToast,
  IonLoading,
  NavContext,
  IonGrid,
  IonRow,
  IonCol,
  IonRouterLink,
} from '@ionic/react';

/*Ionic React lifecycle imports*/
import {
  useIonViewWillEnter
} from '@ionic/react'

/*Third party plugin imports*/

/*Helper imports*/
import {
  trackUserLoggedInWithEmail
} from '../helpers/tracking'

import {
  loginUserWithAuth,
  checkIfUserExists,
  getUserUIDWithAuth,
  sendUserPasswordResetLinkWithAuth
} from '../helpers/users'

/*Image, Data, and Icon imports*/
import LoginGirl from "../assets/images/login-girl.png"

const LoginPhone: React.FC = () => {
  /* Contexts */
const navigationContext = useContext(NavContext)

  /* States and setters */
  const [busy, setBusy] = useState<boolean>(true)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setShowToastMessage] = useState("Default error")

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')


  const emailInputRef = useRef<HTMLIonInputElement>(null)
  const passwordInputRef = useRef<HTMLIonInputElement>(null)
  const loginButtonRef = useRef<HTMLIonButtonElement>(null)

/* Functions */
function removeWhiteSpace(str: string) {
  return str.replace(/\s/g,'');
}

  async function handleEnterPress(e: any, field: React.RefObject<HTMLIonInputElement>) {
    if (e.key === "Enter") {
      if (field === emailInputRef) {
        if (passwordInputRef.current) passwordInputRef.current.setFocus()
      }
      else if (field === passwordInputRef) {
        if (passwordInputRef.current) passwordInputRef.current.setBlur()
        if (loginButtonRef.current) loginButtonRef.current.click()
      }
    }
  }

  async function resetPassword() {
    setBusy(true)
    if (email === '') {
      setShowToastMessage('Apna email address daaliye first')
      setShowToast(true)
    }
    else {
      await sendUserPasswordResetLinkWithAuth(email)
      setShowToastMessage('Aapke email address pe password reset ka link bheja hai')
      setShowToast(true)
    }
    setBusy(false)
  }

  async function login() {
    setBusy(true)
    if (email === '') {
      setShowToastMessage('Apna email address daaliye')
      setShowToast(true)
    }

    else if (password === '') {
      setShowToastMessage('Apna password daaliye')
      setShowToast(true)
    }

    else {
      let loginResult = await loginUserWithAuth(email, password)
      if (loginResult === true) {
        const userExists = await checkIfUserExists(getUserUIDWithAuth())
        console.log(userExists)
        trackUserLoggedInWithEmail()
        if (userExists) {
          // Logged in succesfully
          let tabBar = document.getElementById("tabBar")
          if (tabBar) {
            tabBar.style.display = "flex"
          }
          navigationContext.navigate("/dashboard", "forward")
          window.location.reload()
          // @ts-ignore
          window.Intercom('shutdown')
        }
        else {
          // Force user to enter profile info
          setShowToastMessage('Profile info page pe leja rahe hain')
          setShowToast(true)
          setTimeout(() => {
            navigationContext.navigate("/enterProfileInfo", "forward")
          }, 2000)
        }

      }
      else if (loginResult === 'auth/user-not-found') {
        setShowToastMessage('Yeh email address nahi mila hai. Kya aapko pehle register karna hai?')
        setShowToast(true)
      }
      else if (loginResult === 'auth/invalid-email') {
        setShowToastMessage('Sahi email address daaliye.')
        setShowToast(true)
      }
      else if (loginResult === 'auth/network-request-failed') {
        setShowToastMessage('Apne phone ko Wi-Fi ya Data (Internet) se connected rakhein.')
        setShowToast(true)
      }
      else if (loginResult === 'auth/wrong-password') {
        setShowToastMessage('Galat password daala hai.')
        setShowToast(true)
      }
      else {
        setShowToastMessage('Unknown error.')
        setShowToast(true)
      }
    }
    setBusy(false)
  }

/* Lifecycle methods */
  useIonViewWillEnter(() => {
    // @ts-ignore
    window.Intercom('shutdown')

    let tabBar = document.getElementById("tabBar")
    if (tabBar) {
      tabBar.style.display = "none"
    }
    setBusy(false)
  })


  return (
    <IonPage>
      <IonHeader>

      </IonHeader>
      <IonLoading
        message="Load ho raha hai..."
        duration={0}
        isOpen={busy}
      />
      <IonContent fullscreen className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonTitle size="large">Email Login</IonTitle>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>

              <IonInput
                enterkeyhint="next"
                inputmode="email"
                placeholder="Email address"
                ref={emailInputRef}
                onKeyPress={(e: any) => handleEnterPress(e, emailInputRef)}
                value={email}
                onIonChange={(e: any) => setEmail(removeWhiteSpace(e.target.value))}>
              </IonInput>

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>

              <IonInput
                enterkeyhint="send"
                type="password"
                placeholder="Password"
                ref={passwordInputRef}
                value={password}
                onKeyPress={(e: any) => handleEnterPress(e, passwordInputRef)}
                onIonChange={(e: any) => setPassword(e.target.value)}>
              </IonInput>

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton
                class="verificationButton"
                type="submit"
                ref={loginButtonRef}
                onClick={login}>
                Log In
              </IonButton>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonButton
                class="verificationButton"
                onClick={resetPassword}>
                Password bhool gaye?
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonRouterLink href="/landing">Vaapas Jaaye</IonRouterLink>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="6">
              <img src={LoginGirl} alt="Myna login girl" />
            </IonCol>
          </IonRow>

        </IonGrid>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          buttons={['Retry']}
          duration={4000}
        />

      </IonContent>
    </IonPage>
  );
};

export default LoginPhone;
