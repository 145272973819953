import firebase from 'firebase/app'

import { firestore } from './firebaseConfig'

export class StoreData {
  name: string
  district: string
  address: string
  zipCode: string
  timing: string
  phoneNumber: string
  contact: string
  storeCode: string
  id: string

  constructor(storeDataObject: firebase.firestore.DocumentData) {
    let data = storeDataObject.data()
    this.name = data.name
    this.district = data.district
    this.address = data.address
    this.zipCode = data.zipCode
    this.timing = data.timing
    this.phoneNumber = data.phoneNumber
    this.contact = data.contact
    this.storeCode = data.storeCode
    this.id = storeDataObject.id
  }
}

// Take in a list of stores and export the data to Firestore if the user is an
// admin. This data is  stored in 'src/assets/data/storesData', but the calling
// page does the import to avoid loading a long list in the buffer for non-admin
// pages.
export async function exportStoresDataToFirestore(
  storesData: Array<{
    name: string, district: string, address: string,
    zipCode: string, timing: string, phoneNumber: string,
    contact: string, storeCode: string
  }>,
  storeCategoryID: string) {
  try {
    if (storesData.length === 0) {
      throw new Error('Store data was empty')
    }

    let categoryDocRef = firestore.collection('stores').doc(storeCategoryID);

    console.log("Checking for existing category document...")
    if ((await categoryDocRef.get()).exists) {
      throw new Error(storeCategoryID + ' document must be deleted from the Firebase console first')
    }

    console.log("Generating new category document...")
    await categoryDocRef.set({
      numStores: storesData.length
    })

    console.log("Generating new stores subdocuments...")
    for (let i = 0; i < storesData.length; i++) {
      console.log(i)
      let store = storesData[i]
      await categoryDocRef.collection('stores').doc().set({
        name: store.name,
        district: store.district,
        address: store.address,
        zipCode: store.zipCode,
        timing: store.timing,
        phoneNumber: store.phoneNumber,
        contact: store.contact,
        storeCode: store.storeCode
      })
    }

    return true
  }
  catch (error) {

    if (error.code) {
      return error.code
    }
    else {
      return error
    }
  }

}


// Get all video objects for a given category
export async function getStoresFromCategory(category: string) {
  let collectionRef = firestore.collection('stores').doc(category).collection('stores')
  let stores: StoreData[] = []

  await collectionRef.get().then((querySnapshot) => {

    querySnapshot.forEach((storeDoc) => {
      let newStore = new StoreData(storeDoc)
      stores.push(newStore)

    }
    )
  }).catch((error) => {
    console.log('Error getting stores')
    stores = []
  })

  return stores

}
