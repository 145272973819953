import "firebase/firestore"

import firebase from 'firebase/app'
import { firestore } from './firebaseConfig'
import { UserData } from './users'

// Object class for video data
export class VideoData {
  embedURL: string
  thumbnailURL: string
  youtubeID: string
  title: string
  orderIndex: number
  id: string

  constructor(videoDataObject: firebase.firestore.DocumentData) {
    let data = videoDataObject.data()
    this.youtubeID = data.youtubeID
    this.embedURL = 'https://www.youtube.com/embed/' + this.youtubeID
    this.thumbnailURL = "https://img.youtube.com/vi/" + this.youtubeID + "/mqdefault.jpg"
    this.title = data.title
    this.orderIndex = data.orderIndex
    this.id = videoDataObject.id
  }
}

// Object class for video data
export class LivestreamData {
  videoURL: string
  isActive: boolean
  youtubeID: string

  constructor(livestreamDataObject: firebase.firestore.DocumentData) {
    let data = livestreamDataObject.data()
    this.youtubeID = data.youtubeID
    this.isActive = data.isActive
    this.videoURL = 'https://youtube.com/watch?v=' + this.youtubeID
  }
}

// Object class for video category data
export class VideoCategoryData {
  description: string
  title: string
  quizLink: string
  id: string
  orderIndex: number

  constructor(categoryDataObject: firebase.firestore.DocumentData) {
    let data = categoryDataObject.data()
    this.id = categoryDataObject.id
    this.description = data.description
    this.title = data.title
    this.quizLink = data.quizLink
    this.orderIndex = data.orderIndex

  }
}

// Get all video objects for a given category
export async function getVideosFromCategory(category: VideoCategoryData) {
  let collectionRef = firestore.collection('videos').doc(category.id).collection('videos').orderBy('orderIndex')
  let videos: VideoData[] = []

  await collectionRef.get().then((querySnapshot) => {

    querySnapshot.forEach((videoDoc) => {
      let newVideo = new VideoData(videoDoc)
      videos.push(newVideo)

    }
    )
  }).catch((error) => {
    console.log('Error getting videos')
    videos = []
  })

  return videos

}

// Get all video categories
export async function getVideoCategories() {
  let collectionRef = firestore.collection('videos').orderBy('orderIndex')
  let categories: VideoCategoryData[] = []

  await collectionRef.get().then((querySnapshot) => {

    querySnapshot.forEach((categoryDoc) => {
      let newCategory = new VideoCategoryData(categoryDoc)
      if (newCategory.id !== 'livestream') {
        categories.push(newCategory)
      }
    }
    )
  }).catch((error) => {
    console.log('Error getting categories')
    categories = []
  })

  return categories

}

// Get video category object for given readable id
export async function getCategoryFromID(id: string) {
  try {
    let categoryDoc = await firestore.collection('videos').doc(id).get()
    return new VideoCategoryData(categoryDoc)
  }
  catch (error) {
    console.log('Error getting category from ID')

    return false
  }
}

// Get video category object for given readable id
export async function getLivestream() {
  try {
    let livestreamDoc = await firestore.collection('videos').doc('livestream').get()
    return new LivestreamData(livestreamDoc)
  }
  catch (error) {
    console.log('Error getting livstream object')

    return null
  }
}

// Store a new video in Firestore
export async function createVideo(
  category: VideoCategoryData,
  youtubeID: string,
  title: string) {
  try {
    firestore.collection('videos').doc(category.id).collection('videos').doc().set({
      youtubeID: youtubeID,
      title: title,
      orderIndex: 999 // TODO: Set this dynamically from the admin page
    })
  }
  catch (error) {
    return error.code
  }

}

// Gets the user's feedback for a given video. Creates a new entry if
// one does not exist
export async function getOrCreateUserVideoFeedback(video: VideoData, user: UserData) {
  let videoID = video.id

  if (!user.videoFeedback) {
    // Create a new videoFeedback map

    let userRef = firestore.collection('users').doc(user.uid)

    let videoFeedback: { [key: string]: string } = {}
    videoFeedback[videoID] = 'none'
    user.videoFeedback = videoFeedback

    await userRef.update({
      videoFeedback: user.videoFeedback
    })
    return 'none'
  }
  else if (!user.videoFeedback.hasOwnProperty(videoID)) {

    let userRef = firestore.collection('users').doc(user.uid)

    user.videoFeedback[videoID] = 'none'


    await userRef.update({
      videoFeedback: user.videoFeedback
    })

    return 'none'
  }
  else {

    return user.videoFeedback[videoID]
  }

}

// Sets the user's feedback for a given video
export async function setUserVideoFeedback(video: VideoData, user: UserData, feedback: string) {
  let videoID = video.id


  let userRef = firestore.collection('users').doc(user.uid)

  user.videoFeedback[videoID] = feedback


  await userRef.update({
    videoFeedback: user.videoFeedback
  })

}

// Gets the user's video watch status for a given video. Creates a new entry if
// one does not exist
export async function getOrCreateUserVideoWatched(video: VideoData, user: UserData) {
  let videoID = video.id

  if (!user.videoWatched) {
    // Create a new videoWatched map

    let userRef = firestore.collection('users').doc(user.uid)

    let videoWatched: { [key: string]: boolean } = {}
    videoWatched[videoID] = false
    user.videoWatched = videoWatched

    await userRef.update({
      videoWatched: user.videoWatched
    })
    return false
  }
  else if (!user.videoWatched.hasOwnProperty(videoID)) {

    let userRef = firestore.collection('users').doc(user.uid)

    user.videoWatched[videoID] = false


    await userRef.update({
      videoWatched: user.videoWatched
    })

    return false
  }
  else {

    return user.videoWatched[videoID]
  }

}

// Sets the user's feedback for a given video
export async function toggleUserVideoWatched(video: VideoData, user: UserData) {
  let videoID = video.id


  let userRef = firestore.collection('users').doc(user.uid)

  user.videoWatched[videoID] = !user.videoWatched[videoID]


  await userRef.update({
    videoWatched: user.videoWatched
  })

}
