/*React imports*/
import React, {
  useState,
  useContext,
} from 'react';

/* Ionic component imports */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonLoading,
  NavContext,
  IonText
} from '@ionic/react';

/*Ionic React lifecycle imports*/
import {
  useIonViewWillEnter
} from '@ionic/react'

/*Third party plugin imports*/

/*Helper imports*/

import {
  isUserLoggedIn
} from '../helpers/users'

/*Image, Data, and Icon imports*/
import MynaLogo from "../assets/images/myna-logo.png"

const Landing: React.FC = () => {
  /* Contexts */
const navigationContext = useContext(NavContext)

  /* States and setters */
  const [busy, setBusy] = useState(true)

/* Functions */

/* Lifecycle methods */
  useIonViewWillEnter(() => {
    // @ts-ignore
    window.Intercom('shutdown')

    let tabBar = document.getElementById("tabBar")
    if (tabBar) {
      tabBar.style.display = "none"
    }

    isUserLoggedIn().then((result) => {
      if (result === true) {
        /* User is already logged in */
        let tabBar = document.getElementById("tabBar")
        if (tabBar) {
          tabBar.style.display = "flex"
        }
        console.log("Was logged in...bypassing Landing page")
        setBusy(false)
        navigationContext.navigate("/dashboard", "forward")
      }
      else {
        setBusy(false)
      }
    })
  })

  return (
    <IonPage>
      <IonHeader>
      </IonHeader>
      <IonLoading
        message="Load ho raha hai..."
        duration={0}
        isOpen={busy}
      />
      <IonContent fullscreen className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol size="6">
              <img src={MynaLogo} alt="Myna logo" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonTitle size="large">
                Myna Mobile
            </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonTitle size="small">
                Welcome
            </IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonText>
                Beta V4.9
            </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonTitle size="small">New?</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton class="verificationButton" routerLink="/register">Register</IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonTitle size="small">Log In</IonTitle>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton class="verificationButton" routerLink="/loginEmail">Email aur password</IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonButton class="verificationButton" routerLink="/loginPhone">Phone number</IonButton>
            </IonCol>
          </IonRow>



        </IonGrid>
      </IonContent>
    </IonPage >
  );
};

export default Landing;
