/*React imports*/
import React, {
  useContext
} from 'react';

/* Ionic component imports */
import {
  IonLabel,
  NavContext,
  IonMenu,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonList,
  IonFooter,
  IonIcon
} from '@ionic/react';

/*Ionic React lifecycle imports*/

/*Third party plugin imports*/

import Collapsible from 'react-collapsible'


/*Helper imports*/
import {
  trackUserLoggedOut,
  trackShareButtonPressed
} from '../helpers/tracking'

import {
  logoutUserWithAuth,
  getUserUIDWithAuth
} from '../helpers/users'

/*Image, Data, and Icon imports*/


import {
  createOutline,
  logOut,
  informationCircle,
  hammer,
  rose,
  chevronDown,
  chevronBack,
  shareSocial
} from 'ionicons/icons'

const MenuSystem: React.FC = () => {
  /* Contexts */
  const navigationContext = useContext(NavContext)

  /* States and setters */

  /* Functions */
  async function logOutUser() {
    let logoutResult = await logoutUserWithAuth()
    if (logoutResult === true) {
      // @ts-ignore
      window.Intercom('shutdown')

      let tabBar = document.getElementById("tabBar")
      if (tabBar) {
        tabBar.style.display = "none"
      }
      trackUserLoggedOut()
      navigationContext.navigate("/landing", "forward")
      window.location.reload()
    }
  }

  return (
    <IonMenu
      side="start"
      menuId="first"
      contentId="main"
      disabled={
        (window.location.pathname === '/landing') ||
        (window.location.pathname === '/register') ||
        (window.location.pathname === '/updateProfileInfo') ||
        (window.location.pathname === '/enterProfileInfo') ||
        (window.location.pathname === '/loginEmail') ||
        (window.location.pathname === '/loginPassword')
      }
      swipeGesture={false}
    >
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem
            button
            onClick={() => navigationContext.navigate("/updateProfileInfo", "forward")}>
            <IonIcon icon={createOutline} slot="start" />
            <IonLabel>Mera Profile</IonLabel>
          </IonItem>

        </IonList>

        <Collapsible
          trigger={
            <IonItem button>
              <IonIcon
                icon={hammer}
                slot="start"
              />
              <IonIcon
                icon={chevronDown}
                slot="end"
              />
              <IonLabel>
                Myna Champions
                </IonLabel>
            </IonItem>
          }
          triggerWhenOpen={
            <IonItem button>
              <IonIcon
                icon={hammer}
                slot="start"
              />
              <IonIcon
                icon={chevronBack}
                slot="end"
              />
              <IonLabel>
                Myna Champions
                </IonLabel>
            </IonItem>
          }
          open={false}
        >
          <IonList>
            <IonItem
              button
              href={"https://docs.google.com/forms/d/e/1FAIpQLSdCawsDtAh_lv48J_AZIV7ijfgsD4P4rxvlVaq9QX0AzRQN0g/viewform?usp=pp_url&entry.1829360469=" + getUserUIDWithAuth()}>
              <IonIcon icon={informationCircle} slot="start" />
              <IonLabel>Form 1: Registration</IonLabel>
            </IonItem>
            <IonItem
              button
              href={"https://83p6901wd7r.typeform.com/to/aMGZseUf/?app_uid=" + getUserUIDWithAuth()}>
              <IonIcon icon={informationCircle} slot="start" />
              <IonLabel>Form 2: Details</IonLabel>
            </IonItem>
            <IonItem
              button
              href={"https://83p6901wd7r.typeform.com/to/IVzHVTCr/?app_uid=" + getUserUIDWithAuth()}>
              <IonIcon icon={informationCircle} slot="start" />
              <IonLabel>Form 3: Feedback</IonLabel>
            </IonItem>
          </IonList>
        </Collapsible>

        <IonList>
          <IonItem
            button
            href="https://pbstanford.org/myna-mahila-2020b">
            <IonIcon icon={rose} slot="start" />
            <IonLabel>Wishlist</IonLabel>
          </IonItem>
          <IonItem
            button
            onClick={() => {
              trackShareButtonPressed('menu');
              // @ts-ignore
              if (window.navigator.share) {
                // @ts-ignore
                window.navigator.share({
                  title: 'Share App with Friends',
                  text: 'Check out the Myna Mobile App!',
                  url: 'https://mynamobile.com'
                })
              }

            }}
          >
            <IonIcon icon={shareSocial} slot="start" />
            <IonLabel>Share</IonLabel>
          </IonItem>
        </IonList>

      </IonContent>

      <IonFooter>
        <IonItem>
          <IonLabel style={{ color: "black" }}>App Beta V4.9</IonLabel>
        </IonItem>
        <IonItem
          button
          onClick={logOutUser}>
          <IonIcon icon={logOut} slot="start" />
          <IonLabel>Log Out</IonLabel>
        </IonItem>
      </IonFooter>

    </IonMenu >

  )

}

export default MenuSystem
