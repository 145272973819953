/*React imports*/
import React, {
  useState,
  useEffect
} from 'react';

/* Ionic component imports */
import {
  IonApp,
  IonSpinner
} from '@ionic/react';

import MenuSystem from './components/MenuSystem'
import RoutingSystem from './components/RoutingSystem'

/*Ionic React lifecycle imports*/

/*Third party plugin imports*/

/*Helper imports*/
import './helpers/setUpChatWithMyna.js'

import {
  isUserLoggedIn
 } from './helpers/users'

 import {
   trackCampaignConverted
 } from './helpers/tracking'

/*Image, Data, and Icon imports*/

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './style/variables.css';

/* Custom Styling */
import './style/style.css';
import 'react-calendar/dist/Calendar.css'


const App: React.FC = () => {
  /* Contexts */

  /* Query Parameters */
  const query = new URLSearchParams(window.location.search);

  const campaignID = query.get('campaignID')

  /* States and setters */
  const [busy, setBusy] = useState(true)



  /* Lifecycle methods */
  useEffect(() => {
    // Disable back button functionality
      window.history.pushState({}, '', window.top.location.pathname + window.top.location.search)
      window.addEventListener('popstate', (event) => {
        console.log('Trying to pop state')
        event.preventDefault()
        window.history.pushState({}, '',  window.top.location.pathname + window.top.location.search)
      })


    if (campaignID) {
      console.log('Tracking campaign ' + campaignID)
      trackCampaignConverted(campaignID)

    }

    // if (window.navigator.onLine === false) {
    //   alert('Apne phone ko Wi-Fi ya Data (Internet) se connected rakhein.')
    //   window.location.reload()
    // }
    isUserLoggedIn().then((result) => {
      if (result === true) {
        /* User is logged in */
        setBusy(false)
      }
      else {
        /* User needs to register or log in */
        let tabBar = document.getElementById("tabBar")
        if (tabBar) {
          tabBar.style.display = "none"
        }
        window.history.replaceState({}, '', '/landing')
        setBusy(false)
        console.log("Wasn't logged in...moving to Landing page if inside app")
      }
    })

  }, [])

  return (
    <IonApp>
      {busy ? <IonSpinner /> : <RoutingSystem />}
      {busy ? <IonSpinner /> : <MenuSystem />}
    </IonApp>
  )


};

export default App;
