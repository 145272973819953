/*React imports*/
import React, {
} from 'react';
import {
  Redirect,
  Route
} from 'react-router-dom';

/* Ionic component imports */
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
} from '@ionic/react';

/*Ionic React lifecycle imports*/
import {
  IonReactRouter
} from '@ionic/react-router';
import Register from '../pages/Register'
import LoginEmail from '../pages/LoginEmail'
import LoginPhone from '../pages/LoginPhone'
import Landing from '../pages/Landing'
import Dashboard from '../pages/Dashboard'
import PublicChat from '../pages/PublicChat'
import Videos from '../pages/Videos'
import EnterProfileInfo from '../pages/EnterProfileInfo'
import UpdateProfileInfo from '../pages/UpdateProfileInfo'
import Doctors from '../pages/Doctors'
import HealthInfo from '../pages/HealthInfo'
import Admin from '../pages/Admin'


/*Third party plugin imports*/

/*Helper imports*/

/*Image, Data, and Icon imports*/
import {
  logoYoutube,
  fitness,
  home,
  medkit,
  chatbubbles
} from 'ionicons/icons'

const NoMatchPage = () => {
  return (
    <h3>404 - Not found</h3>
  );
};

const RoutingSystem: React.FC = () => {
  return (
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet id="main">
          <Route path="/register" component={Register} exact={true} />
          <Route path="/loginEmail" component={LoginEmail} exact={true} />
          <Route path="/loginPhone" component={LoginPhone} exact={true} />
          <Route path="/landing" component={Landing} exact={true} />
          <Route path="/dashboard" component={Dashboard} exact={true} />
          <Route path="/friendChat" component={PublicChat} exact={true} />
          <Route path="/lessons" component={Videos} exact={true} />
          <Route path="/enterProfileInfo" component={EnterProfileInfo} exact={true} />
          <Route path="/updateProfileInfo" component={UpdateProfileInfo} exact={true} />
          <Route path="/doctors" component={Doctors} exact={true} />
          <Route path="/healthInfo" component={HealthInfo} exact={true} />
          <Route path="/admin" component={Admin} exact={true} />
          <Route path="/" render={() => <Redirect to="/landing" />} exact={true} />
          <Route component={NoMatchPage} />
        </IonRouterOutlet>

        <IonTabBar slot="bottom" id="tabBar">
          <IonTabButton tab="dashboard" href="/dashboard">
            <IonLabel>Home</IonLabel>
            <IonIcon icon={home} />
          </IonTabButton>
          <IonTabButton tab="friendChat" href="/friendChat">
            <IonLabel>Friend Chat</IonLabel>
            <IonIcon icon={chatbubbles} />
          </IonTabButton>
          <IonTabButton tab="healthInfo" href="/healthInfo">
            <IonLabel>Sehat</IonLabel>
            <IonIcon icon={fitness} />
          </IonTabButton>
          <IonTabButton tab="lessons" href="/lessons">
            <IonLabel>Lessons</IonLabel>
            <IonIcon icon={logoYoutube} />
          </IonTabButton>
          <IonTabButton tab="doctors" href="/doctors">
            <IonLabel>Doctors</IonLabel>
            <IonIcon icon={medkit} />
          </IonTabButton>
        </IonTabBar>

      </IonTabs>
    </IonReactRouter>
  )
}

export default RoutingSystem
