/*React imports*/
import React, {
  useState,
  useContext,
  useRef
} from 'react';

/* Ionic component imports */
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonInput,
  IonButton,
  IonToast,
  IonLoading,
  NavContext,
  IonGrid,
  IonRow,
  IonCol,
  IonRouterLink,
  IonText,
  IonItem,
  IonIcon
} from '@ionic/react';

/*Ionic React lifecycle imports*/
import {
  useIonViewDidEnter
} from '@ionic/react'

/*Third party plugin imports*/
import firebase from 'firebase/app'
import "firebase/auth"

import Collapsible from "react-collapsible"


/*Helper imports*/
import {
  trackUserRegisteredWithEmail,
  trackUserRegisteredWithPhoneNumber
} from '../helpers/tracking'

import {
  registerUserWithAuth,
  getRecaptchaVerifierWithAuth,
  loginSendOTPWithAuth,
  checkIfUserExists,
  logoutUserWithAuth
} from '../helpers/users'

/*Image, Data, and Icon imports*/
import {
  chevronDown,
  chevronBack
} from 'ionicons/icons'

const Register: React.FC = () => {
  /* Contexts */
  const navigationContext = useContext(NavContext)

  /* States and setters */
  const [busy, setBusy] = useState<boolean>(true)
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setShowToastMessage] = useState("Default error")

  const [email, setEmail] = useState('')
  const [cEmail, setCEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [sendOTPButtonText, setSendOTPButtonText] = useState('OTP Bheje')
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<firebase.auth.RecaptchaVerifier | null>(null)
  const [confirmationResult, setConfirmationResult] = useState<firebase.auth.ConfirmationResult | null>(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('+91')

  const [inputOTP, setInputOTP] = useState('')
  const [hideOTPInput, setHideOTPInput] = useState(true)

  const emailInputRef = useRef<HTMLIonInputElement>(null)
  const confirmEmailInputRef = useRef<HTMLIonInputElement>(null)
  const passwordInputRef = useRef<HTMLIonInputElement>(null)
  const confirmPasswordInputRef = useRef<HTMLIonInputElement>(null)
  const registerEmailButtonRef = useRef<HTMLIonButtonElement>(null)

  const countryCodeInputRef = useRef<HTMLIonInputElement>(null)
  const phoneInputRef = useRef<HTMLIonInputElement>(null)
  const sendOTPButtonRef = useRef<HTMLIonButtonElement>(null)
  const otpInputRef = useRef<HTMLIonInputElement>(null)
  const submitOTPButtonRef = useRef<HTMLIonButtonElement>(null)

  /* Functions */
  function removeWhiteSpace(str: string) {
    return str.replace(/\s/g, '');
  }

  async function handleEnterPress(e: any, field: React.RefObject<HTMLIonInputElement>) {
    if (e.key === "Enter") {
      console.log(field)
      if (field === emailInputRef) {
        if (confirmEmailInputRef.current) confirmEmailInputRef.current.setFocus()
      }
      else if (field === confirmEmailInputRef) {
        if (passwordInputRef.current) passwordInputRef.current.setFocus()
      }
      else if (field === passwordInputRef) {
        if (confirmPasswordInputRef.current) confirmPasswordInputRef.current.setFocus()
      }
      else if (field === confirmPasswordInputRef) {
        if (confirmPasswordInputRef.current) confirmPasswordInputRef.current.setBlur()
        if (registerEmailButtonRef.current) registerEmailButtonRef.current.click()
      }
      else if (field === countryCodeInputRef) {
        if (phoneInputRef.current) phoneInputRef.current.setFocus()
      }
      else if (field === phoneInputRef) {
        if (phoneInputRef.current) phoneInputRef.current.setBlur()
        if (sendOTPButtonRef.current) sendOTPButtonRef.current.click()
      }
      else if (field === otpInputRef) {
        if (otpInputRef.current) otpInputRef.current.setBlur()
        if (submitOTPButtonRef.current) submitOTPButtonRef.current.click()
      }
    }
  }
  async function registerWithEmail() {
    setBusy(true)
    if ((email === '') || (cEmail === '')) {
      setShowToastMessage('Apna email do baar nahi dala hai')
      setShowToast(true)
    }
    else if (password !== confirmPassword) {
      setShowToastMessage('Passwords match nahi ho rahe hain')
      setShowToast(true)
    }
    else if (email !== cEmail) {
      setShowToastMessage('Emails match nahi ho rahe hain')
      setShowToast(true)
    }
    else if ((password === '') || (confirmPassword === '')) {
      setShowToastMessage('Apna password do baar nahi dala hai')
      setShowToast(true)
    }
    else {
      let creationResult = await registerUserWithAuth(email, password)
      if (creationResult === true) {
        // Login worked
        // addCurrentUserToDatabase(email, userName, fullName)
        trackUserRegisteredWithEmail()
        navigationContext.navigate("/enterProfileInfo", "forward")
      }
      else {
        if (creationResult === 'auth/weak-password') {
          setShowToastMessage('Aapke password mein kam se kam 6 akshar istemaal kariye.')
          setShowToast(true)
        }
        else if (creationResult === 'auth/invalid-email') {
          setShowToastMessage('Sahi email address de.')
          setShowToast(true)
        }
        else if (creationResult === 'auth/email-already-in-use') {
          setShowToastMessage('Yeh email address pehle se darj ho chuka hai. Krupaya LOG IN kariye.')
          setShowToast(true)
          await logoutUserWithAuth()
        }
        else if (creationResult === 'auth/network-request-failed') {
          setShowToastMessage('Apne phone ko Wi-Fi ya Data (Internet) se connected rakhein.')
          setShowToast(true)
        }
        else {
          setShowToastMessage('Unknown error: ' + creationResult)
          setShowToast(true)
        }
      }
    }

    setBusy(false)
  }

  async function sendOTP() {

    // Force typescript to acknowledge that recaptchaVerifier will never be null below
    if (recaptchaVerifier === null) {
      return
    }

    if (phoneNumber === '') {
      setShowToastMessage('Phone number darj nahi kiya gaya hai.')
      setShowToast(true)
    }
    else if (countryCode === '') {
      setShowToastMessage('Country code darj nahi hua hai')
      setShowToast(true)
    }

    else {
      console.log('Trying to log in')
      let sendOTPResult = await loginSendOTPWithAuth(recaptchaVerifier, countryCode + phoneNumber)

      console.log("Finished loginSendOTPWithAuth function")
      if (sendOTPResult[0] === true) {
        // Logged in succesfully
        console.log("Sent OTP")
        setSendOTPButtonText('OTP fir se bheje')
        setHideOTPInput(false)
        setConfirmationResult(sendOTPResult[1])
      }
      else if (sendOTPResult[1] === 'auth/invalid-phone-number') {
        setShowToastMessage('Phone number sahi tarah se darj nahi hua hai.')
        setShowToast(true)
      }
      else if (sendOTPResult[1] === 'auth/code-expired') {
        setShowToastMessage('OTP expire hua hai. OTP ke liye fir se request kariye.')
        setShowToast(true)
      }
      else if (sendOTPResult[1] === 'auth/internal-error') {
        setShowToastMessage('Apne phone ko Wi-Fi ya Data (Internet) se connected rakhein.')
        setShowToast(true)
      }
      else {
        setShowToastMessage('Unknown error: ' + sendOTPResult[1])
        console.log(sendOTPResult[1])
        setShowToast(true)
      }
    }

  }

  async function submitOTP() {
    if (confirmationResult === null) {
      return
    }

    setBusy(true)
    confirmationResult.confirm(inputOTP).then((result) => {
      console.log("Logged in successfully")

      if (result.user === null) {
        setShowToastMessage('Got a null user object.')
        setShowToast(true)
      }
      else {
        const userExists = checkIfUserExists(result.user.uid)
        console.log(userExists)
        console.log(result.user.uid)
        userExists.then((result) => {
          if (result === true) {
            setShowToastMessage('Is phone number dwara pehele aap darj kar chuke hai. Krupiya LOG IN kariye.')
            setShowToast(true)
            logoutUserWithAuth()
            setTimeout(() => {
              window.location.reload()
            }, 3000)
          }
          else if (result === false) {
            trackUserRegisteredWithPhoneNumber()
            navigationContext.navigate("/enterProfileInfo", "forward")
          }
        })
      }
    }).catch((error) => {
      if (error.code === 'auth/invalid-verification-code') {
        setShowToastMessage('OTP sahi nahi hai.')
        setShowToast(true)
      }
      else {
        setShowToastMessage('Unknown OTP validation error: ' + error.code)
        setShowToast(true)
        console.log(error.code)
      }
    })
    setBusy(false)
  }


  /* Lifecycle methods */
  useIonViewDidEnter(() => {
    // @ts-ignore
    window.Intercom('shutdown')

    console.log("Creating recaptcha")
    setRecaptchaVerifier(getRecaptchaVerifierWithAuth("recaptchaContainer"));
    let tabBar = document.getElementById("tabBar")
    if (tabBar) {
      tabBar.style.display = "none"
    }
    setBusy(false)

  })



  return (
    <IonPage>
      <IonHeader>
      </IonHeader>
      <IonLoading
        message="Load ho raha hai..."
        duration={0}
        isOpen={busy}
      />
      <IonContent fullscreen className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonTitle size="large">Register</IonTitle>
            </IonCol>
          </IonRow>

          <Collapsible
            trigger={
              <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <IonTitle
                      size="small"
                      color="tertiary"
                    >
                      Phone Number
                  </IonTitle>

                    <IonIcon
                      icon={chevronDown}
                      slot="end"
                      color="tertiary"
                    />
                  </IonItem>

                </IonCol>
              </IonRow>
            }
            triggerWhenOpen={
              <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <IonTitle
                      size="small"
                      color="tertiary"
                    >
                      Phone Number
                  </IonTitle>

                    <IonIcon
                      icon={chevronBack}
                      slot="end"
                      color="tertiary"
                    />
                  </IonItem>

                </IonCol>
              </IonRow>
            }
            open={true}

          >

            <IonRow>
              <IonCol>
                <IonText>Aapka phone jinke paas hai woh iss account ko istemaal kar sakte hain</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>(Agar aapke paas email address nahi hai toh hi ise istemaal kariye)</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="2" />
              <IonCol size="2">
                <IonInput
                  enterkeyhint="next"
                  inputmode="tel"
                  type="tel"
                  value={countryCode}
                  ref={countryCodeInputRef}
                  onKeyPress={(e: any) => handleEnterPress(e, countryCodeInputRef)}
                  onIonChange={(e: any) => setCountryCode(e.target.value)}>
                </IonInput>
              </IonCol>
              <IonCol>
                <IonInput
                  enterkeyhint="send"
                  inputmode="tel"
                  type="number"
                  placeholder="Phone number"
                  ref={phoneInputRef}
                  onKeyPress={(e: any) => handleEnterPress(e, phoneInputRef)}
                  onIonChange={(e: any) => setPhoneNumber(e.target.value)}>
                </IonInput>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  class="verificationButton"
                  type="submit"
                  ref={sendOTPButtonRef}
                  onClick={sendOTP}>
                  {sendOTPButtonText}
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonInput
                  enterkeyhint="send"
                  hidden={hideOTPInput}
                  type="number"
                  ref={otpInputRef}
                  onKeyPress={(e: any) => handleEnterPress(e, otpInputRef)}
                  placeholder="OTP"
                  onIonChange={(e: any) => setInputOTP(e.target.value)}>
                </IonInput>
                <div id="recaptchaContainer" />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  class="verificationButton"
                  type="submit"
                  hidden={hideOTPInput}
                  ref={submitOTPButtonRef}
                  onClick={submitOTP}>
                  Phone se Register
              </IonButton>
              </IonCol>
            </IonRow>

          </Collapsible>

          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>

          <Collapsible
            trigger={
              <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <IonTitle
                      size="small"
                      color="tertiary"
                    >
                      Email aur Password
                  </IonTitle>

                    <IonIcon
                      icon={chevronDown}
                      slot="end"
                      color="tertiary"
                    />
                  </IonItem>

                </IonCol>
              </IonRow>
            }
            triggerWhenOpen={
              <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <IonTitle
                      size="small"
                      color="tertiary"
                    >
                      Email aur Password
                  </IonTitle>

                    <IonIcon
                      icon={chevronBack}
                      slot="end"
                      color="tertiary"
                    />
                  </IonItem>

                </IonCol>
              </IonRow>
            }
            open={false}
          >

            <IonRow>
              <IonCol>
                <IonText>Ye tareeka zyada safe aur surakshit hai</IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText>(Agar aapke paas email address hai toh ise istemaal kariye)</IonText>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonInput
                  enterkeyhint="next"
                  inputmode="email"
                  placeholder="Apna email address"
                  ref={emailInputRef}
                  onKeyPress={(e: any) => handleEnterPress(e, emailInputRef)}
                  value={email}
                  onIonChange={(e: any) => setEmail(removeWhiteSpace(e.target.value))} >
                </IonInput>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonInput
                  enterkeyhint="next"
                  placeholder="Apna email address (fir se)"
                  ref={confirmEmailInputRef}
                  value={cEmail}
                  inputmode="email"
                  onKeyPress={(e: any) => handleEnterPress(e, confirmEmailInputRef)}
                  onIonChange={(e: any) => setCEmail(removeWhiteSpace(e.target.value))}>
                </IonInput>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonInput
                  enterkeyhint="next"
                  type="password"
                  placeholder="Naya password (kam se kam 6 akshar)"
                  ref={passwordInputRef}
                  value={password}
                  onKeyPress={(e: any) => handleEnterPress(e, passwordInputRef)}
                  onIonChange={(e: any) => setPassword(e.target.value)}>
                </IonInput>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonInput
                  enterkeyhint="send"
                  type="password"
                  placeholder="Naya password (fir se)"
                  ref={confirmPasswordInputRef}
                  value={confirmPassword}
                  onKeyPress={(e: any) => handleEnterPress(e, confirmPasswordInputRef)}
                  onIonChange={(e: any) => setConfirmPassword(e.target.value)}>
                </IonInput>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  class="verificationButton"
                  type="submit"
                  ref={registerEmailButtonRef}
                  onClick={registerWithEmail}>
                  Register
              </IonButton>
              </IonCol>
            </IonRow>
          </Collapsible>

          <IonRow>
            <IonCol>
              <hr />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonRouterLink href="/landing">Vaapas Jaaye</IonRouterLink>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          buttons={['Retry']}
          duration={4000}
        />



      </IonContent>
    </IonPage>
  );
};

export default Register;
